import React, { useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames/bind';

import {
    MainContainer,
    ChatContainer,
    MessageList,
    Message,
    MessageInput,
    Avatar,
    TypingIndicator,
    MessageSeparator,
    ConversationHeader,
    InfoButton,
    VoiceCallButton,
    VideoCallButton,
    Sidebar,
    Conversation,
    ConversationList,
    Search,
    ExpansionPanel,
    AvatarGroup,
    SendButton,
    AttachmentButton,
    InputToolbox,
} from '@chatscope/chat-ui-kit-react';
import Lightbox from 'yet-another-react-lightbox';
import Captions from 'yet-another-react-lightbox/plugins/captions';
import Fullscreen from 'yet-another-react-lightbox/plugins/fullscreen';
import Slideshow from 'yet-another-react-lightbox/plugins/slideshow';
import Thumbnails from 'yet-another-react-lightbox/plugins/thumbnails';
import Video from 'yet-another-react-lightbox/plugins/video';
import Zoom from 'yet-another-react-lightbox/plugins/zoom';
import Counter from 'yet-another-react-lightbox/plugins/counter';
import Download from 'yet-another-react-lightbox/plugins/download';

import 'yet-another-react-lightbox/plugins/counter.css';
import 'yet-another-react-lightbox/plugins/captions.css';
import 'yet-another-react-lightbox/plugins/thumbnails.css';
import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';

import { FaCircle } from 'react-icons/fa';
import { FaRegFileLines } from 'react-icons/fa6';

import {
    getListContactableService,
    getListConversationService,
    getConversationExistService,
    attachmentConversationService,
} from '../../services/user/conversationService';
import { selectUser } from '../../redux/features/authUser/authSlide';
import { useWebSocket } from '../../context/WebSocketContext';
import { compareTimeString, formatTimeMessage, formatTimeSeparator } from '../../utils/convert/convertTimeUtil';
import { websocketType } from '../../constants';
import { getListMessageService } from '../../services/user/chatService';
import { ChatSidebarItemComponent, ImageMessage, ListAttachmentMessage, MessageFileComponent } from '../../components';
import { icons, images } from '../../assets';
import styles from './ChatPage.module.scss';
import Modal2 from '@components/common/Modal2/Modal2';
import converSize from '../../utils/convert/convertSize';

const cx = classNames.bind(styles);

const ChatPage = () => {
    const dispatch = useDispatch();
    const user = useSelector(selectUser);
    const ref = useRef();
    const conversationId = useRef(null);
    const listMessageRef = useRef(null);
    const attachmentRef = useRef(null);
    let typingIntervalRef = null;
    let typingTimeoutRef = null;
    const userTyping = useRef(false);
    const [lightboxOpen, setLightboxOpen] = useState({
        open: false,
        images: [],
    });
    const [dialog, setDialog] = useState({
        show: false,
        title: '',
        content: '',
    });
    const [message, setMessage] = useState('');
    const [isTyping, setIsTyping] = useState(false);
    const { sendMessage, registerListener } = useWebSocket();
    const [groupedMessages, setGroupedMessages] = useState([]);
    const [conversationData, setConversationData] = useState({
        page: 1,
        limit: 20,
        data: [],
        firstLoad: true,
        canLoadMore: true,
        isLoading: false,
    });
    const [messagesData, setMessagesData] = useState({
        page: 1,
        limit: 20,
        data: [],
        firstLoad: true,
        canLoadMore: true,
        isLoading: false,
        isTop: false,
    });
    const [contactable, setContactableData] = useState({
        page: 1,
        limit: 20,
        data: [],
        firstLoad: true,
        canLoadMore: true,
        isLoading: false,
    });
    const [allFile, setAllFile] = useState([]);
    const [fileMessage, setFileMessage] = useState([]);
    const [isUploading, setIsUploading] = useState(false);
    const maxImageSize = 2 * 1024 * 1024;
    const maxFileSize = 5 * 1024 * 1024;

    const acceptFile = '.pdf,.doc,.docx,.jpg,.jpeg,.png,.svg';

    const separatorComponent = (created_at) => ({
        type: 'separator',
        data: {
            created_at: created_at,
        },
    });

    const messageComponent = (message, position = 'normal') => {
        return {
            type: 'message',
            data: message,
            position: position,
        };
    };

    const groupMessages = (messages) => {
        const dataReverse = [...messages].reverse();
        const result = [];

        const addSeparator = (date) => {
            if (result.length === 0 || result[result.length - 1].type !== 'separator') {
                result.push(separatorComponent(date));
            }
        };

        const addMessage = (message, type) => {
            result.push(messageComponent(message, type));
        };

        const isTimeDifferent = (date1, date2) => {
            return compareTimeString(date1, date2);
        };

        dataReverse.forEach((message, index) => {
            const prevMessage = dataReverse[index - 1];
            const nextMessage = dataReverse[index + 1];

            if (index === 0 || isTimeDifferent(message.created_at, prevMessage.created_at)) {
                addSeparator(message.created_at);
            }

            let messageType;
            if (index === dataReverse.length - 1) {
                messageType = 'first';
            } else if (!nextMessage || message.account_id !== nextMessage.account_id || isTimeDifferent(nextMessage.created_at, message.created_at)) {
                if (
                    index !== 0 &&
                    prevMessage.type !== 'separator' &&
                    (message.account_id !== prevMessage.account_id || isTimeDifferent(message.created_at, prevMessage.created_at))
                ) {
                    messageType = 'first';
                } else {
                    messageType = 'last';
                }
            } else if (index === 0) {
                if (nextMessage.account_id === message.account_id) {
                    messageType = 'normal';
                } else {
                    messageType = 'first';
                }
            } else {
                messageType = 'normal';
            }

            addMessage(message, messageType);

            if (nextMessage && isTimeDifferent(nextMessage.created_at, message.created_at)) {
                addSeparator(nextMessage.created_at);
            }
        });

        return result;
    };

    const handleTyping = (e) => {
        setMessage(e);
        if (!typingIntervalRef) {
            sendMessage({
                data: {
                    conversation_id: conversationId.current,
                    user_id: user.id,
                },
                type: websocketType.USER_TYPING,
            });

            typingIntervalRef = setInterval(() => {
                sendMessage({
                    data: {
                        conversation_id: conversationId.current,
                        user_id: user.id,
                    },
                    type: websocketType.USER_TYPING,
                });
            }, 1000);

            if (typingTimeoutRef) {
                clearTimeout(typingTimeoutRef);
            }

            typingTimeoutRef = setTimeout(() => {
                clearInterval(typingIntervalRef);
                typingIntervalRef = null;
                typingTimeoutRef = null;
            }, 2000);
        }
    };

    const handleUserTypingInComming = (data) => {
        let newData = JSON.parse(JSON.stringify(data));
        if (newData.user_id !== user.id && conversationId.current === newData.conversation_id) {
            setIsTyping(true);
        }

        if (userTyping.current) {
            clearTimeout(userTyping.current);
        }

        userTyping.current = setTimeout(() => {
            setIsTyping(false);
        }, 1000);

        return () => {
            if (userTyping.current) {
                clearTimeout(userTyping.current);
            }
        };
    };

    const handleSendMessage = (content) => {
        const messageData = {
            content: message,
            type: 'text',
        };
        if (conversationId.current === 0) {
            messageData.members = conversationData.data[0].members.map((m) => m.id);
        } else {
            messageData.conversation_id = conversationId.current;
        }
        if (isHasAttachment()) {
            messageData.attachments = fileMessage.filter((f) => !f.isRemove).map((f) => f.name);
            messageData.type = 'attachment';
        }

        const data = {
            data: messageData,
            type: websocketType.NEW_MESSAGE,
        };

        sendMessage(data);
        if (typingIntervalRef) {
            clearInterval(typingIntervalRef);
            typingIntervalRef = null;
        }
        resetData();
        listMessageRef.current.scrollToBottom();
    };

    const handleActiveFakeConversation = (id) => {
        setMessagesData((prev) => ({
            ...prev,
            page: 1,
            limit: 20,
            firstLoad: true,
            data: [],
            canLoadMore: true,
            isLoading: false,
            isTop: false,
        }));
        conversationId.current = id;
    };

    const handleActiveConversation = (id) => {
        if (id === conversationId.current) {
            return;
        }

        setConversationData((prev) => ({
            ...prev,
            data: prev.data.filter((c) => c.id !== 0),
        }));

        setMessagesData((prev) => ({
            ...prev,
            page: 1,
            limit: 20,
            firstLoad: true,
            data: [],
            canLoadMore: true,
            isLoading: false,
            isTop: false,
        }));
        conversationId.current = id;
    };

    const handleNewMessageInComming = (data) => {
        let newData = JSON.parse(JSON.stringify(data));
        newData.type = newData.message_type || 'text';

        if (newData.conversation_id === conversationId.current) {
            setMessagesData((prev) => ({
                ...prev,
                data: [newData, ...prev.data],
            }));

            if (isTyping) {
                setIsTyping(false);
            }
            updateNewMessage(newData);
        } else {
            updateNewMessage(newData);
            setMessagesData((prev) => ({
                ...prev,
                firstLoad: false,
            }));
        }
    };

    const updateNewMessage = (newData) => {
        setConversationData((prev) => {
            const matchedConversation = prev.data.find((c) => c.id === newData.conversation_id);
            const updatedConversation = { ...matchedConversation, last_message: newData };

            const otherConversations = prev.data.filter((c) => c.id !== newData.conversation_id);

            return {
                ...prev,
                data: [updatedConversation, ...otherConversations],
            };
        });
    };

    const handleNewConversationInComming = (data) => {
        let newData = JSON.parse(JSON.stringify(data));
        newData.type = newData.conversation_type;
        newData.count_member = newData.members.length;

        setConversationData((prev) => ({
            ...prev,
            data: [newData, ...prev.data.filter((c) => c.id !== 0)],
        }));
    };

    const handleGetConversation = (params) => {
        if (!conversationData.canLoadMore || conversationData.isLoading) {
            return;
        }
        setConversationData((prev) => ({
            ...prev,
            isLoading: true,
        }));
        getListConversationService(params)
            .then((res) => {
                if (res.status === 200) {
                    setConversationData((prev) => ({
                        ...prev,
                        data: res.data.data,
                        firstLoad: false,
                        canLoadMore: res.data.data.length === prev.limit,
                        isLoading: false,
                    }));
                } else {
                    if (conversationData.firstLoad) {
                        setConversationData((prev) => ({
                            ...prev,
                            firstLoad: false,
                            isLoading: false,
                        }));
                    }
                }
            })
            .catch((err) => {
                console.log(err);
                if (conversationData.firstLoad) {
                    setConversationData((prev) => ({
                        ...prev,
                        firstLoad: false,
                        isLoading: false,
                    }));
                }
            });
    };

    const handleGetMessages = (params) => {
        if (!messagesData.canLoadMore) {
            return;
        }

        getListMessageService(conversationId.current, params)
            .then((res) => {
                if (res.status === 200) {
                    setMessagesData((prev) => ({
                        ...prev,
                        firstLoad: false,
                        data: [...prev.data, ...res.data.data],
                        canLoadMore: res.data.data.length === prev.limit,
                        isLoading: false,
                        page: prev.page + 1,
                        isTop: false,
                    }));
                } else {
                    if (messagesData.firstLoad) {
                        setMessagesData((prev) => ({
                            ...prev,
                            firstLoad: false,
                            isLoading: false,
                        }));
                    }
                }
            })
            .catch((err) => {
                console.log(err);
                if (messagesData.firstLoad) {
                    setMessagesData((prev) => ({
                        ...prev,
                        firstLoad: false,
                        isLoading: false,
                    }));
                }
            });
    };

    const handleGetCvApplication = (params) => {
        if (!contactable.canLoadMore || contactable.isLoading) {
            return;
        }
        setContactableData((prev) => ({
            ...prev,
            isLoading: true,
        }));
        getListContactableService(params)
            .then((res) => {
                if (res.status === 200) {
                    setContactableData((prev) => ({
                        ...prev,
                        data: res.data.data,
                        firstLoad: false,
                        canLoadMore: res.data.data.length === prev.limit,
                        isLoading: false,
                    }));
                } else {
                    if (contactable.firstLoad) {
                        setContactableData((prev) => ({
                            ...prev,
                            firstLoad: false,
                            isLoading: false,
                        }));
                    }
                }
            })
            .catch((err) => {
                console.log(err);
                if (contactable.firstLoad) {
                    setContactableData((prev) => ({
                        ...prev,
                        firstLoad: false,
                        isLoading: false,
                    }));
                }
            });
    };

    const handleScrollListMessage = () => {
        if (messagesData.isLoading || !messagesData.canLoadMore) {
            return;
        }
        setMessagesData((prev) => ({
            ...prev,
            isLoading: true,
        }));

        const params = {
            conversation_id: conversationId.current,
            skip: (messagesData.page - 1) * messagesData.limit,
            limit: messagesData.limit,
        };
        handleGetMessages(params);
    };

    const checkExistConversation = (item) => () => {
        const members = [item.id];
        const params = {
            members: members,
        };

        getConversationExistService(params)
            .then((res) => {
                if (res.status === 200) {
                    handleActiveConversation(res.data.data.id);
                } else if (res.status === 404) {
                    let fakeConversation = {
                        id: 0,
                        name: item.full_name,
                        type: 'single',
                        members: [item],
                        last_message: null,
                    };

                    setConversationData((prev) => ({
                        ...prev,
                        data: [fakeConversation, ...prev.data.filter((c) => c.id !== 0)],
                    }));
                    handleActiveFakeConversation(0);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const getAvatarConversation = (members) => {
        if (!members) {
            return images.avatar_default;
        }
        const business = members.find((m) => m.role === 'business');
        return business?.avatar || business?.company?.logo || images.avatar_default;
    };

    const getAvatar = (member) => {
        if (!member) {
            return images.avatar_default;
        }
        if (member.role === 'business') {
            return member.avatar || member.company?.logo || images.avatar_default;
        } else {
            return member.avatar || images.avatar_default;
        }
    };

    const getDefaultAvatar = (e) => {
        e.target.src = images.avatar_default;
    };

    const getMessageContent = (item) => {
        if (item?.last_message?.content) {
            if (item.last_message.account_id === user.id) {
                return 'Bạn: ' + item.last_message.content;
            } else {
                return item.last_message.content;
            }
        } else if (item.id === 0) {
            return '';
        } else if (item?.last_message?.type === 'image') {
            return 'Hình ảnh';
        } else if (item?.last_message?.type === 'file') {
            return 'Tệp đính kèm';
        } else {
            return 'Tin nhắn mới';
        }
    };

    const getCurrentConversation = () => {
        return conversationData.data.find((c) => c.id === conversationId.current);
    };

    const setSildeImages = (images) => {
        setLightboxOpen({
            open: true,
            images: images,
        });
    };

    const closeLightbox = () => {
        setLightboxOpen({
            open: false,
            images: [],
        });
    };

    const handleClickAttachment = () => {
        attachmentRef.current.value = null;
        attachmentRef.current.click();
    };

    const handleChangeAttachment = (e) => {
        const files = e.target.files;
        if (files.length > 0) {
            for (let i = 0; i < files.length; i++) {
                if (files[i].type.includes('image')) {
                    if (files[i].size > maxImageSize) {
                        setDialog({
                            show: true,
                            title: `Kích thước ảnh ${files[i].name} quá lớn`,
                            content: 'Kích thước ảnh quá lớn, vui lòng chọn ảnh khác',
                        });
                        return;
                    }
                } else {
                    if (files[i].size > maxFileSize) {
                        setDialog({
                            show: true,
                            title: `Kích thước file ${files[i].name} quá lớn`,
                            content: 'Kích thước file quá lớn, vui lòng chọn file khác',
                        });
                        return;
                    }
                }
            }
            const addFiles = Array.from(files).map((file) => ({
                file: file,
                isRemove: false,
            }));
            setAllFile((prev) => [...prev, ...addFiles]);
        }
    };

    const uploadAttachment = (file, index) => {
        const body = new FormData();
        body.append('attachments', file);

        attachmentConversationService(conversationId.current, body)
            .then((res) => {
                if (res.status === 200) {
                    setFileMessage((prev) => [
                        ...prev,
                        {
                            name: res.data.data.name,
                            index: prev.length + index,
                            isRemove: allFile[index].isRemove,
                        },
                    ]);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const isHasAttachment = () => {
        return allFile.filter((f) => !f.isRemove).length > 0;
    };

    const removeAttachment = (index) => {
        setAllFile((prev) => {
            prev[index].isRemove = true;
            return [...prev];
        });

        if (fileMessage[index]) {
            setFileMessage((prev) => {
                prev[index].isRemove = true;
                return [...prev];
            });
        }
    };

    const canSend = () => {
        return !isUploading && ((message && message.trim()) || isHasAttachment());
    };

    const resetData = () => {
        setAllFile([]);
        setFileMessage([]);
        setMessage('');
        attachmentRef.current.value = null;
    };

    useEffect(() => {
        if (allFile.length > fileMessage.length) {
            if (!isUploading) {
                setIsUploading(true);
            }
            const files = allFile.slice(fileMessage.length);
            files.forEach((file, index) => {
                uploadAttachment(file.file, index);
            });
        }
    }, [allFile]);

    useEffect(() => {
        if (allFile.length === fileMessage.length && isUploading) {
            setIsUploading(false);
        }
    }, [fileMessage]);

    useEffect(() => {
        const handleNewMessage = (data) => {
            handleNewMessageInComming(data);
        };
        const handleUserTyping = (data) => {
            handleUserTypingInComming(data);
        };

        registerListener(websocketType.NEW_MESSAGE, handleNewMessage);
        registerListener(websocketType.USER_TYPING, handleUserTyping);
        registerListener(websocketType.NEW_CONVERSATION, handleNewConversationInComming);

        return () => {};
    }, [dispatch]);

    useEffect(() => {
        setMessage('');
    }, [conversationId.current]);

    useEffect(() => {
        const params = {
            skip: (conversationData.page - 1) * conversationData.limit,
            limit: conversationData.limit,
        };
        handleGetConversation(params);
    }, []);

    useEffect(() => {
        if (conversationId.current) {
            const params = {
                conversation_id: conversationId.current,
                skip: (messagesData.page - 1) * messagesData.limit,
                limit: messagesData.limit,
            };
            handleGetMessages(params);
        }
    }, [conversationId.current]);

    useEffect(() => {
        if (!conversationId.current && conversationData.data.length > 0) {
            setMessagesData((prev) => ({
                ...prev,
                firstLoad: true,
            }));
            conversationId.current = conversationData.data[0].id;
        }
    }, [conversationData.data]);

    useEffect(() => {
        setGroupedMessages(groupMessages(messagesData.data));
    }, [messagesData.data]);

    useEffect(() => {
        const params = {
            skip: (contactable.page - 1) * contactable.limit,
            limit: contactable.limit,
        };
        handleGetCvApplication(params);
    }, []);

    return (
        <div>
            <div className="dialog-container">
                <Modal2
                    show={dialog.show}
                    handleHidden={() => setDialog({ ...dialog, show: false })}
                    header={
                        <div className={cx('header-dialog')}>
                            <h4 className={cx('title-dialog')}>{dialog.title}</h4>
                        </div>
                    }
                    body={
                        <div className={cx('body-dialog')}>
                            <p className={cx('content-dialog')}>{dialog.content}</p>
                        </div>
                    }
                    footer={
                        <div className={cx('footer-dialog')}>
                            <button className={cx('btn-cancel-dialog')} onClick={() => setDialog({ ...dialog, show: false })}>
                                Đóng
                            </button>
                        </div>
                    }
                />
            </div>
            <Lightbox
                plugins={[Thumbnails, Counter]}
                thumbnails={{
                    position: 'bottom',
                    width: 100,
                    height: 60,
                    border: '1px solid #fff',
                    borderRadius: 10,
                    padding: 2,
                    gap: 10,
                }}
                counter={{ container: { style: { top: 0, bottom: 'unset' } } }}
                carousel={{ preload: lightboxOpen.images.length > 2 ? 2 : 1 }}
                open={lightboxOpen.open}
                close={closeLightbox}
                slides={lightboxOpen.images}
            />
            <input ref={attachmentRef} type="file" accept={acceptFile} style={{ display: 'none' }} multiple onChange={handleChangeAttachment} />

            <div
                className={cx('list-attachment')}
                style={{
                    display: isHasAttachment() ? 'flex' : 'none',
                }}
            >
                <ListAttachmentMessage attachments={allFile} removeAttachment={removeAttachment} isUploading={isUploading} />
            </div>

            <MainContainer responsive className={cx('wrapper')}>
                <Sidebar position="left" loading={conversationData.firstLoad} className={cx('sidebar')}>
                    <Search placeholder="Nhập tên công ty, tên nhà tuyển dụng" />
                    <ConversationList>
                        {conversationData.data.map((item, index) => (
                            <Conversation
                                style={{
                                    height: '90px',
                                    alignItems: 'center',
                                }}
                                key={index}
                                name={
                                    <span className={cx('conversation-name')}>
                                        {item.type === 'group' ? item.name : item.members.filter((m) => m.id !== user.id)[0].full_name}
                                    </span>
                                }
                                info={
                                    <span className={cx('conversation-info')}>
                                        {getMessageContent(item)}
                                        {item.id === 0 ? '' : <FaCircle className={cx('conversation-info-icon')} />}
                                        {item?.last_message?.created_at
                                            ? formatTimeMessage(item?.last_message?.created_at)
                                            : item.id === 0
                                            ? ''
                                            : 'Tin nhắn mới'}
                                    </span>
                                }
                                active={conversationId.current === item.id}
                                onClick={() => handleActiveConversation(item.id)}
                            >
                                {item.type === 'group' ? (
                                    <AvatarGroup max={4} size="md" className={cx('avatar-group-conversation')}>
                                        {item.members.map((m, i) => (
                                            <Avatar
                                                className={cx('avatar')}
                                                key={i}
                                                src={getAvatar(m)}
                                                name={m.full_name}
                                                onError={(e) => getDefaultAvatar(e)}
                                            />
                                        ))}
                                    </AvatarGroup>
                                ) : (
                                    <Avatar
                                        className={cx('avatar')}
                                        size="lg"
                                        src={getAvatarConversation(item.members)}
                                        name={item.members.filter((m) => m.id !== user.id)[0].full_name}
                                        onError={(e) => getDefaultAvatar(e)}
                                    />
                                )}
                            </Conversation>
                        ))}
                    </ConversationList>
                </Sidebar>
                <ChatContainer style={{ position: 'relative' }}>
                    <ConversationHeader>
                        {getCurrentConversation()?.type === 'group' ? (
                            <AvatarGroup max={4} size="sm" style={{ width: '43px' }}>
                                {getCurrentConversation()?.members.map((m, i) => (
                                    <Avatar className={cx('avatar')} key={i} src={getAvatar(m)} name={m.full_name} onError={(e) => getDefaultAvatar(e)} />
                                ))}
                            </AvatarGroup>
                        ) : (
                            <Avatar
                                className={cx('avatar')}
                                src={getAvatarConversation(getCurrentConversation()?.members)}
                                name={getCurrentConversation()?.members.filter((m) => m.id !== user.id)[0].full_name}
                                onError={(e) => getDefaultAvatar(e)}
                            />
                        )}
                        <ConversationHeader.Content
                            userName={getCurrentConversation()?.name || getCurrentConversation()?.members.filter((m) => m.id !== user.id)[0].full_name}
                            info="Online"
                        />
                    </ConversationHeader>
                    <MessageList
                        className={cx('message-list')}
                        typingIndicator={isTyping ? <TypingIndicator content="Đang nhập..." /> : null}
                        ref={listMessageRef}
                        loadingMore={messagesData.isLoading}
                        loadingMorePosition="top"
                        onYReachStart={messagesData.isTop ? null : handleScrollListMessage}
                        disableOnYReachWhenNoScroll={false}
                        style={{ paddingBottom: isHasAttachment() ? '105px' : '50px' }}
                    >
                        <div>
                            {[0, null].includes(conversationId.current) && <MessageSeparator content="Hãy bắt đầu cuộc trò chuyện" />}
                            {groupedMessages.map((message, index) => {
                                if (message.type === 'separator') {
                                    return <MessageSeparator key={index} content={formatTimeSeparator(message.data.created_at)} />;
                                }
                                return (
                                    <Message
                                        key={index}
                                        model={{
                                            // message: message.data.content,
                                            sentTime: formatTimeMessage(message.data.created_at),
                                            sender: message.data.user.full_name,
                                            direction: message.data.user.id === user.id ? 'outgoing' : 'incoming',
                                            position: 'single',
                                        }}
                                        className={cx('message')}
                                    >
                                        <Avatar
                                            className={cx('avatar')}
                                            src={getAvatar(message.data.user)}
                                            name={message.data.user.full_name}
                                            style={{
                                                visibility: message.position === 'normal' ? 'hidden' : 'visible',
                                            }}
                                            onError={(e) => getDefaultAvatar(e)}
                                        />
                                        {message.data.type === 'text' && (
                                            <Message.CustomContent>
                                                <div className={cx('text-message')}>{message.data.content}</div>
                                            </Message.CustomContent>
                                        )}
                                        {message.data.type === 'image' && (
                                            <Message.CustomContent key={index} className={`${cx('image-message')} cs-message__image`}>
                                                <img
                                                    src={message.data.attachments[0].url}
                                                    alt="image"
                                                    className={cx('image')}
                                                    onClick={() => setSildeImages(message.data.attachments.map((img) => ({ src: img.url })))}
                                                />
                                                {message.data.attachments.length > 1 && (
                                                    <div
                                                        className={cx('image-overlay')}
                                                        onClick={() => setSildeImages(message.data.attachments.map((img) => ({ src: img.url })))}
                                                    >
                                                        <span>+{message.data.attachments.length - 1}</span>
                                                    </div>
                                                )}
                                            </Message.CustomContent>
                                        )}

                                        {message.data.type === 'file' && (
                                            <Message.CustomContent className={'cs-message__file'} key={index}>
                                                <a href={message.data.attachments[0].url} download target="_blank" rel="noreferrer" className={cx('file-link')}>
                                                    <div className={cx('file-icon')}>
                                                        <FaRegFileLines className={cx('icon')} />
                                                    </div>
                                                    <div className={cx('file-info')}>
                                                        <div className={cx('file-name')}>{message.data.attachments[0].name}</div>
                                                        <div className={cx('file-size')}>{converSize(message.data.attachments[0].size)}</div>
                                                    </div>
                                                </a>
                                            </Message.CustomContent>
                                        )}
                                        {((message.type !== 'separator' &&
                                            message.data.account_id !== user.id &&
                                            (index === 0 ||
                                                groupedMessages[index - 1].type === 'separator' ||
                                                message.data.account_id !== user.id ||
                                                message.position === 'first') &&
                                            index > 0 &&
                                            groupedMessages[index - 1].type !== 'separator' &&
                                            groupedMessages[index - 1].data.user.id !== message.data.user.id) ||
                                            (index > 0 && groupedMessages[index - 1].type === 'separator' && message.data.user.id !== user.id) ||
                                            (index > 0 &&
                                                groupedMessages[index - 1].type !== 'separator' &&
                                                groupedMessages[index - 1].data.user.id !== message.data.user.id &&
                                                message.data.user.id !== user.id)) && <Message.Header>{message.data.user.full_name}</Message.Header>}
                                    </Message>
                                );
                            })}
                        </div>
                    </MessageList>

                    <InputToolbox style={{ padding: '10px 20px' }}>
                        <AttachmentButton onClick={handleClickAttachment} disabled={isUploading} />
                        <MessageInput
                            style={{ flex: 1 }}
                            placeholder="Nhập tin nhắn..."
                            value={message}
                            onChange={handleTyping}
                            ref={ref}
                            autoFocus={true}
                            attachButton={false}
                            sendButton={false}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter' && !e.shiftKey && canSend()) {
                                    e.preventDefault();
                                    handleSendMessage(message);
                                }
                            }}
                        />
                        <SendButton onClick={() => handleSendMessage(message)} disabled={!canSend()} />
                    </InputToolbox>
                </ChatContainer>
                <Sidebar position="right" className={cx('sidebar')} loading={contactable.firstLoad}>
                    <ExpansionPanel open={true} title="Tin tuyển dụng đã ứng tuyển" className={cx('expansion-panel')}>
                        {contactable.data.map((item, index) => (
                            <ChatSidebarItemComponent key={index} contact={item} onClick={checkExistConversation(item)} />
                        ))}
                    </ExpansionPanel>
                </Sidebar>
            </MainContainer>
        </div>
    );
};

export default ChatPage;
