import classNames from 'classnames/bind';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import styles from './SkeletonJobDashboardComponent.module.scss';

const cx = classNames.bind(styles);

const SkeletonJobDashboardComponent = () => {
    return (
        <div className={cx('wrapper')}>
            <div className={cx('header')}>
                <Skeleton count={1} width={'100px'} height={40} className={cx('button')} />
                <Skeleton count={1} width={'100px'} height={40} className={cx('button')} />
            </div>
            <div className={cx('container')}>
                <div className={cx('content')}>
                    <Skeleton count={1} width={'100px'} height={20} className={cx('title-1')} />
                    <Skeleton count={1} height={40} className={cx('path-item')} />
                    <Skeleton count={1} width={'200px'} height={20} className={cx('title-2')} />
                    <Skeleton count={1} height={40} className={cx('path-item')} />
                    <Skeleton width={'100px'} height={20} className={cx('title-3')} />
                    <div className={cx('box')}>
                        <div className={cx('box-path')}>
                            <Skeleton count={1} height={40} className={cx('path-item')} />
                        </div>
                        <div className={cx('box-path')}>
                            <Skeleton count={1} height={40} className={cx('path-item')} />
                        </div>
                    </div>
                    <Skeleton count={1} width={'150px'} height={20} className={cx('title-4')} />
                    <Skeleton count={1} height={40} className={cx('path-item')} />
                    <Skeleton count={1} width={'300px'} height={20} className={cx('title-5')} />
                    <Skeleton count={1} height={40} className={cx('path-item')} />
                    <Skeleton count={1} width={'250px'} height={20} className={cx('title-6')} />
                    <Skeleton count={1} height={40} className={cx('path-item')} />
                </div>
                <div className={cx('content')}>
                    <Skeleton count={1} width={'100px'} height={20} className={cx('title-1')} />
                    <Skeleton count={1} height={40} className={cx('path-item')} />
                    <Skeleton count={1} width={'200px'} height={20} className={cx('title-2')} />
                    <Skeleton count={1} height={40} className={cx('path-item')} />
                    <Skeleton count={1} width={'150px'} height={20} className={cx('title-4')} />
                    <Skeleton count={1} height={40} className={cx('path-item')} />
                    <Skeleton count={1} width={'300px'} height={20} className={cx('title-5')} />
                    <Skeleton count={1} height={40} className={cx('path-item')} />
                    <Skeleton count={1} width={'250px'} height={20} className={cx('title-6')} />
                    <Skeleton count={1} height={40} className={cx('path-item')} />
                </div>
                <div className={cx('content')}>
                    <Skeleton count={1} width={'100px'} height={20} className={cx('title-1')} />
                    <Skeleton count={1} height={80} className={cx('path-item')} />
                    <Skeleton count={1} width={'200px'} height={20} className={cx('title-2')} />
                    <Skeleton count={1} height={80} className={cx('path-item')} />
                    <Skeleton count={1} width={'150px'} height={20} className={cx('title-4')} />
                    <Skeleton count={1} height={80} className={cx('path-item')} />
                    <Skeleton count={1} width={'300px'} height={20} className={cx('title-5')} />
                    <Skeleton count={1} height={40} className={cx('path-item')} />
                    <Skeleton count={1} width={'250px'} height={20} className={cx('title-6')} />
                    <Skeleton count={1} height={40} className={cx('path-item')} />
                </div>
                <div className={cx('content')}>
                    <Skeleton count={1} width={'300px'} height={20} className={cx('title-5')} />
                    <Skeleton count={1} height={40} className={cx('path-item')} />
                    <Skeleton count={1} width={'250px'} height={20} className={cx('title-6')} />
                    <Skeleton count={1} height={60} className={cx('path-item')} />
                </div>
            </div>
        </div>
    );
};

export default SkeletonJobDashboardComponent;
