import { URL_OAUTH2 } from '@configs';

export default {
    HOMEPAGE: '/',
    JOB_SEARCH: '/viec-lam',
    JOB_FILTER: '/tim-viec-lam-moi-nhat',
    JOB_SEARCH_DETAIL: '/viec-lam/chi-tiet',
    COMPANY_DETAIL: '/cong-ty',
    MATCHING_JOBS: '/viec-lam-phu-hop',
    APPLIED_JOBS: '/lich-su-ung-tuyen',
    SUBPAGE: '/subpage',
    LOGIN: '/login',
    REGISTER: '/register',
    CHAT: '/chat',
    MANAGER_REGISTER: '/tuyen-dung/app/register',
    MANAGER_LOGIN: '/tuyen-dung/app/login',
    DASHBOARD_HOME: '/tuyen-dung/app/dashboard',
    DASHBOARD_POST: '/tuyen-dung/app/emloyer-verify?context=create-job',
    DASHBOARD_SEARCH: '/tuyen-dung/app/emloyer-verify?context=search-cv',
    DASHBOARD_CAMPAIGN: '/tuyen-dung/app/campaign',
    DASHBOARD_CAMPAIGN_PUSHING: '/tuyen-dung/app/campaign?filter=pushing',
    DASHBOARD_CAMPAIGN_CV: '/tuyen-dung/app/campaign?filter=cv',
    DASHBOARD_CV_MANAGEMENT: '/tuyen-dung/app/cv-management',
    DASHBOARD_CART: '/tuyen-dung/app/cart',
    DASHBOARD_CONNECT: '/tuyen-dung/app/connect',
    DASHBOARD_ACCOUNT_SETTING: '/tuyen-dung/app/account-setting',
    DASHBOARD_INVOICE: '/tuyen-dung/app/invoice',
    DASHBOARD_POST_CAMPAIGN: '/tuyen-dung/app/jobs/create-campaign',
    DASHBOARD_POST_JOB: '/tuyen-dung/app/jobs/create-job',
    DASHBOARD_POST_JOB_EDIT: '/tuyen-dung/app/jobs',
    DASHBOARD_APPROVAL_REQUEST_JOB: '/tuyen-dung/app/jobs',
    DASHBOARD_RECRUIREMENT_CAMPAIGNS: '/tuyen-dung/app/recruitment-campaigns',
    DASHBOARD_RECRUIREMENT_CAMPAIGNS_CREATE: '/tuyen-dung/app/recruitment-campaigns/create',
    DASHBOARD_SUGGESTION: '/tuyen-dung/app/suggestions',
    DASHBOARD_SETTING_PASSWORD: '/tuyen-dung/app/account/settings/password',
    DASHBOARD_SETTING_INFO: '/tuyen-dung/app/account/settings/info',
    DASHBOARD_SETTING_BUSINESS_LICENSE: '/tuyen-dung/app/account/settings/business-license',
    DASHBOARD_SETTING_COMPANY: '/tuyen-dung/app/account/settings/company',
    DASHBOARD_VERIFFY: '/tuyen-dung/app/account/verify',
    DASHBOARD_MANAGER_BUSINESS: '/tuyen-dung/app/manager-business',
    DASHBOARD_CHAT: '/tuyen-dung/app/chat',
    DASHBOARD_PREVIEW: '/tuyen-dung/app/preview',
    URL_OAUTH2: URL_OAUTH2,
};
