import classNames from 'classnames/bind';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { RxEnvelopeClosed } from 'react-icons/rx';
import { LuPhone } from 'react-icons/lu';
import { FaChevronRight } from 'react-icons/fa6';

import styles from './DashboardMember.module.scss';
import { icons, images } from '@assets';
import { selectUser } from '@redux/features/authUser/authSlide';
import path from './../../../../../constants/path';

const cx = classNames.bind(styles);

const DashboardMember = () => {
    const employer = useSelector(selectUser);

    const listProgress = [
        {
            id: 1,
            ranking: 'Member',
            point: 0,
        },
        {
            id: 2,
            ranking: 'Silver',
            point: 300,
        },
        {
            id: 3,
            ranking: 'Gold',
            point: 800,
        },
        {
            id: 4,
            ranking: 'Platinum',
            point: 1500,
        },
        {
            id: 5,
            ranking: 'Diamond',
            point: 2500,
        },
    ];

    return (
        <div className={cx('wrapper')}>
            <div className={cx('container')}>
                <div className={cx('box-info')}>
                    <div className={cx('header-info')}>
                        <div className={cx('info')}>
                            <div className={cx('avatar-info')}>
                                <div className={cx('avatar')}>
                                    <img src={employer?.avatar ? employer.avatar : images.avatar_default} alt="avatar" className={cx('avatar-img')} />
                                </div>
                            </div>
                            <div className={cx('employer-info')}>
                                <div className={cx('employer-name')}>{employer?.full_name}</div>
                                <div className={cx('employer-id')}>Mã NTD: {employer?.id}</div>
                                <div className={cx('employer-contact')}>
                                    <div className={cx('employer-contact-item')}>
                                        <RxEnvelopeClosed className={cx('icon')} />
                                        <span className={cx('text')}>{employer?.email}</span>
                                    </div>

                                    <div className={cx('employer-contact-item')}>
                                        <LuPhone className={cx('icon')} />
                                        <span className={cx('text')}>{employer?.phone_number}</span>
                                    </div>
                                </div>
                            </div>
                            <div className={cx('member')}>
                                <div className={cx('member-item')}>
                                    <Link to={path.DASHBOARD_SETTING_INFO} className={cx('member-link')}>
                                        <img src={images.member} alt="member" className={cx('member-img')} />
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={cx('header-subtract')}>
                        <img src={images.subtract.default} alt="subtract" className={cx('subtract')} />
                    </div>
                </div>
                <div className={cx('box-rank')}>
                    <div className={cx('box-content')}>
                        <div className={cx('employer-progress')}></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DashboardMember;
