import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import TippyText from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

import { FaChevronDown, FaEye, FaPlus } from 'react-icons/fa6';
import { FaPen } from 'react-icons/fa';
import { HiOutlineChevronDoubleRight, HiOutlineChevronDoubleLeft } from 'react-icons/hi';
import { CgMail } from 'react-icons/cg';
import { MdOutlineManageAccounts } from 'react-icons/md';

import styles from './RecruitmentCampaignPage.module.scss';
import { SelectionComponent } from '@components/common';
import { IoSearchOutline } from 'react-icons/io5';
import path from '../../../constants/path';
import { getListCampaignService } from '@services/business/campaignService';
import { JobStatus } from '@constants';
import { SkeletonRecruimentCampaignComponent } from '@components/skeleton';
import { selectUser, selectUserRole } from '@redux/features/authUser/authSlide';
import { images } from '../../../assets';
import { groupRole, role } from '../../../constants';
import { GrLocation } from 'react-icons/gr';
import { updateStatusCampaignService } from '../../../services/business/campaignService';
import useToast from '@hooks/useToast';

const cx = classNames.bind(styles);

const RecruitmentCampaignPage = () => {
    const navigate = useNavigate();
    const user = useSelector(selectUser);
    const userRole = useSelector(selectUserRole);
    const { handleAddToast } = useToast();

    const [keyword, setKeyword] = useState('');

    const listFilterCampaign = [
        {
            id: 1,
            name: 'Tất cả chiến dịch',
            filter_by: 'all',
        },
        {
            id: 2,
            name: 'Chỉ chiến dịch đang mở',
            filter_by: 'only_open',
        },
        {
            id: 3,
            name: 'Có CV ứng tuyển mới cần xem',
            filter_by: 'has_new_cv',
        },
        {
            id: 4,
            name: 'Tin tuyển dụng đang hiển thị',
            filter_by: 'has_publishing_job',
        },
        {
            id: 5,
            name: 'Tin tuyển dụng hết hạn hiển thị',
            filter_by: 'expired_job',
        },
        {
            id: 6,
            name: 'Tin tuyển dụng đang xét duyệt',
            filter_by: 'waiting_approval_job',
        },
    ];

    const listTableHead = [
        {
            id: 1,
            name: 'Chiến dịch',
        },
        {
            id: 3,
            name: 'Tin tuyển dụng',
        },
        {
            id: 4,
            name: groupRole.ADMIN.includes(userRole) ? 'Thông tin nhà tuyển dụng' : 'CV ứng tuyển',
        },
    ];

    const [campaigns, setCampaigns] = useState(null);

    const [filterCampaign, setFilterCampaign] = useState({
        filter_by: 1,
        page: 1,
        loading: true,
    });

    const requestSetStatusCampaign = (id, status) => {
        updateStatusCampaignService(id, { status })
            .then((res) => {
                if (res.status === 200) {
                    setFilterCampaign({ ...filterCampaign, loading: true });
                    if (status === 'stopped') {
                        handleAddToast('Thành công', 'Đóng chiến dịch thành công', 'success');
                        setCampaigns((prev) => {
                            return {
                                ...prev,
                                campaigns: prev.campaigns.map((item) => {
                                    if (item.id === id) {
                                        item.status = 'stopped';
                                        if (item.job) {
                                            item.job.status = 'stopped';
                                        }
                                    }
                                    return item;
                                }),
                            };
                        });
                    }
                    if (status === 'open') {
                        handleAddToast('Thành công', 'Mở chiến dịch thành công', 'success');
                        setCampaigns((prev) => {
                            return {
                                ...prev,
                                campaigns: prev.campaigns.map((item) => {
                                    if (item.id === id) {
                                        item.status = 'open';
                                    }
                                    return item;
                                }),
                            };
                        });
                    }
                } else {
                    handleAddToast('Cảnh báo', 'Cập nhật trạng thái thất bại', 'warning');
                }
            })
            .catch((err) => {
                handleAddToast('Cảnh báo', 'Cập nhật trạng thái thất bại', 'error');
                console.log(err);
            });
    };

    const handlePrevPage = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setFilterCampaign({ ...filterCampaign, page: filterCampaign.page - 1, loading: true });
    };

    const handleNextPage = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setFilterCampaign({ ...filterCampaign, page: filterCampaign.page + 1, loading: true });
    };

    const handleFilterCampaign = (filter) => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setFilterCampaign({ ...filterCampaign, filter_by: filter, page: 1, loading: true });
    };

    useEffect(() => {
        const params = {
            skip: (filterCampaign.page - 1) * 10,
            limit: 10,
            sort_by: 'created_at',
            order_by: 'desc',
        };
        keyword !== '' && (params.keyword = keyword);

        filterCampaign.filter_by !== 1 && (params.filter_by = listFilterCampaign.find((item) => item.id === filterCampaign.filter_by)?.filter_by);

        filterCampaign.loading &&
            getListCampaignService(params)
                .then((res) => {
                    if (res.status === 200) {
                        setCampaigns({
                            campaigns: res.data.data.campaigns,
                            count: res.data.data.count,
                        });
                        if (res.data.data.campaigns.length === 0 && user?.role === 'business') {
                            // navigate(path.DASHBOARD_RECRUIREMENT_CAMPAIGNS_CREATE);
                            setFilterCampaign({ ...filterCampaign, loading: false });
                        } else {
                            setTimeout(() => {
                                setFilterCampaign({ ...filterCampaign, loading: false });
                            }, 500);
                        }
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
    }, [filterCampaign]);

    return (
        <div className={cx('wrapper')}>
            <div className={cx('auth-modal')}></div>
            <div className={cx('container')}>
                <div className={cx('breadcrumb-box')}>
                    <div className={cx('breadcrumb-box-title')}>
                        <h6 className={cx('breadcrumb')}>Quản lý chiến dịch tuyển dụng</h6>
                    </div>
                    {userRole === role.BUSINESS && (
                        <div className={cx('breadcrumb-box-button')}>
                            <div className={cx('button-list')}>
                                <Link to={path.DASHBOARD_RECRUIREMENT_CAMPAIGNS_CREATE}>
                                    <button className={cx('button', 'button-post')}>
                                        <FaPlus className={cx('icon-plus')} />
                                        Thêm chiến dịch mới
                                    </button>
                                </Link>
                            </div>
                        </div>
                    )}
                </div>
                <div className={cx('content')}>
                    <div className={cx('content-list')}>
                        <div className={cx('box-search')}>
                            <div className={cx('box-filter-campaign')}>
                                <SelectionComponent
                                    header={() => (
                                        <div className={cx('header-select')}>
                                            <div className={cx('container-select')}>
                                                <span className={cx('result')}>
                                                    {listFilterCampaign.find((item) => item.id === filterCampaign.filter_by)?.name}
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                    body={() => (
                                        <ul className={cx('ul-select')}>
                                            {listFilterCampaign.map((item) => (
                                                <li
                                                    key={item.id}
                                                    className={cx('item', { active: item.id === filterCampaign.filter_by })}
                                                    onClick={() => handleFilterCampaign(item.id)}
                                                >
                                                    <span className={cx('text')}>{item.name}</span>
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                    icon={() => <FaChevronDown className={cx('icon-care')} />}
                                    itemSelect={listFilterCampaign.find((item) => item.id === filterCampaign.filter_by)?.name}
                                    maxHeight={'300px'}
                                    styleDropdown={{
                                        right: 'auto',
                                        left: '0',
                                        top: '45px',
                                        width: '250px',
                                        borderRadius: '4px',
                                        border: '1px solid #e8e8e8',
                                        borderTop: 'none',
                                    }}
                                    styleButton={{ marginRight: '10px' }}
                                />
                            </div>
                            <div className={cx('box-search-campaign')}>
                                <input
                                    type="text"
                                    placeholder="Tìm chiến dịch (Nhấn enter để tìm kiếm)"
                                    className={cx('input-search')}
                                    value={keyword}
                                    onChange={(e) => setKeyword(e.target.value)}
                                    onKeyPress={(e) => {
                                        if (e.key === 'Enter') {
                                            setFilterCampaign({ ...filterCampaign, page: 1, loading: true });
                                        }
                                    }}
                                />
                                <IoSearchOutline
                                    className={cx('icon-search')}
                                    onClick={() => setFilterCampaign({ ...filterCampaign, page: 1, loading: true })}
                                />
                            </div>
                        </div>
                        <table className={cx('table')}>
                            <thead className={cx('table-head')}>
                                <tr>
                                    {listTableHead.map((item) => (
                                        <th key={item.id} className={cx('table-head-item')}>
                                            {item.name}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody className={cx('table-body')}>
                                {campaigns && !filterCampaign.loading ? (
                                    campaigns.campaigns?.length > 0 ? (
                                        campaigns.campaigns.map((item) => (
                                            <tr key={item.id} className={cx('table-body-row')}>
                                                <td className={cx('table-body-item')}>
                                                    <div className={cx('item-campaign')}>
                                                        {userRole === role.BUSINESS && (
                                                            <label className={cx('item-switch')}>
                                                                <input
                                                                    type="checkbox"
                                                                    className={cx('switch-input')}
                                                                    value={item.status === 'open'}
                                                                    checked={item.status === 'open'}
                                                                    onChange={() =>
                                                                        requestSetStatusCampaign(item.id, item.status === 'open' ? 'stopped' : 'open')
                                                                    }
                                                                />
                                                                <span className={cx('switch-slider')}></span>
                                                            </label>
                                                        )}
                                                        <div className={cx('item-content')}>
                                                            <div className={cx('item-content-id')}>
                                                                <span className={cx('id')}>#{item.id}</span>
                                                            </div>
                                                            <a
                                                                href={`${path.DASHBOARD_RECRUIREMENT_CAMPAIGNS}/${item.id}`}
                                                                className={cx('item-content-title')}
                                                            >
                                                                {item.title}
                                                            </a>
                                                            <br />
                                                            <div className={cx('item-content-cv')}>
                                                                {item.count_apply > 0 ? `Có ${item.count_apply} CV ứng tuyển` : 'Chưa có CV nào'}
                                                            </div>
                                                            <div className={cx('item-content-action', 'item-content-absolute')}>
                                                                <a
                                                                    href={`${path.DASHBOARD_RECRUIREMENT_CAMPAIGNS}/${item.id}`}
                                                                    className={cx('item-content-link')}
                                                                >
                                                                    Xem báo cáo
                                                                </a>
                                                            </div>
                                                            <div className={cx('item-content-action', 'item-content-absolute')}>
                                                                <a
                                                                    href={`${path.DASHBOARD_RECRUIREMENT_CAMPAIGNS}/${item.id}?active_tab=apply_cv`}
                                                                    className={cx('item-content-link')}
                                                                >
                                                                    Xem CV ứng tuyển
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className={cx('table-body-item')}>
                                                    <div className={cx('table-body-item-info')}>
                                                        <div className={cx('info-job')}>
                                                            <div className={cx('job-position')}>
                                                                <span className={cx('id')}>{item.job?.id ? `ID: ${item.job.id}` : 'Chiến dịch đang tắt'}</span>
                                                                {item.job ? <div className={cx('item-content-title')}>{item.job?.title}</div> : null}
                                                                {item.job ? (
                                                                    <div className={cx('tag-list')}>
                                                                        <div className={cx('job-status', `job-status-${item.job?.status}`)}>
                                                                            <span className={cx('job-status-text')}>
                                                                                {JobStatus.find((status) => status.value === item.job?.status)?.name}
                                                                            </span>
                                                                        </div>
                                                                        {item.lastest_approval_request &&
                                                                            item.lastest_approval_request.status !== item.job?.status && (
                                                                                <div
                                                                                    className={cx(
                                                                                        'job-status',
                                                                                        `job-status-${item.lastest_approval_request.status}`,
                                                                                    )}
                                                                                >
                                                                                    {/* <span className={cx('job-status-text')}>
                                                                                        {
                                                                                            JobRequestStatus.find(
                                                                                                (status) =>
                                                                                                    status.value === item.lastest_approval_request.status,
                                                                                            )?.name
                                                                                        }
                                                                                    </span> */}
                                                                                    {item.lastest_approval_request.status === 'pending' &&
                                                                                        item.status !== 'pending' && (
                                                                                            <span className={cx('job-status-text')}>Đang chờ cập nhật</span>
                                                                                        )}
                                                                                </div>
                                                                            )}
                                                                    </div>
                                                                ) : null}
                                                            </div>
                                                            <div
                                                                className={cx('item-content-absolute', 'info-job-action')}
                                                                style={{ display: userRole === role.BUSINESS && item.job ? 'block' : 'none' }}
                                                            >
                                                                <FaPen className={cx('icon-edit')} />
                                                                <Link
                                                                    to={`${path.DASHBOARD_POST_JOB_EDIT}/${item.job?.id}/edit`}
                                                                    className={cx('item-content-link')}
                                                                >
                                                                    Chỉnh sửa
                                                                </Link>
                                                            </div>
                                                            <div
                                                                className={cx('item-content-absolute', 'info-job-action')}
                                                                style={{ display: item.job ? 'flex' : 'none' }}
                                                            >
                                                                <FaEye className={cx('icon-edit')} />
                                                                <Link to={`${path.DASHBOARD_PREVIEW}/${item.job?.id}`} className={cx('item-content-link')}>
                                                                    Xem chi tiết
                                                                </Link>
                                                            </div>
                                                            {item.lastest_approval_request && item.lastest_approval_request.status === 'pending' && (
                                                                <div
                                                                    className={cx('item-content-absolute', 'info-job-action')}
                                                                    style={{ display: item.job ? 'flex' : 'none' }}
                                                                >
                                                                    <FaEye className={cx('icon-edit')} />
                                                                    <Link
                                                                        to={`${path.DASHBOARD_APPROVAL_REQUEST_JOB}/${item.job.id}/approval-request`}
                                                                        className={cx('item-content-link')}
                                                                    >
                                                                        Xem yêu cầu cập nhật
                                                                    </Link>
                                                                </div>
                                                            )}
                                                            {userRole === role.BUSINESS && (
                                                                <div
                                                                    className={cx('item-content-absolute', 'info-job-action')}
                                                                    style={{ display: item.job ? 'none' : 'flex' }}
                                                                >
                                                                    <FaPlus className={cx('icon-edit')} />
                                                                    <Link
                                                                        to={`${path.DASHBOARD_POST_JOB}?campaign_id=${item.id}`}
                                                                        className={cx('item-content-link')}
                                                                    >
                                                                        Đăng tin tuyển dụng
                                                                    </Link>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </td>
                                                {userRole === role.BUSINESS ? (
                                                    <td className={cx('table-body-item')}>
                                                        <div className={cx('table-body-item-cv')}>
                                                            {item.count_apply > 0 ? (
                                                                <div className={cx('item-cv')}>
                                                                    {item.latest_cvs.slice(0, 3).map((cv, index) => (
                                                                        <TippyText
                                                                            key={index}
                                                                            content={cv.user?.full_name}
                                                                            placement="top"
                                                                            interactive={true}
                                                                            trigger="mouseenter"
                                                                            animation="shift-away"
                                                                            theme="light"
                                                                            delay={[100, 0]}
                                                                        >
                                                                            <Link to={`${path.DASHBOARD_CV_MANAGEMENT}?campaign_id=${item.id}`}>
                                                                                <div className={cx('item-cv-avatar')}>
                                                                                    <img
                                                                                        content={cv.user?.full_name}
                                                                                        src={cv.user?.avatar || images.avatar_default}
                                                                                        alt="avatar"
                                                                                        className={cx('avatar')}
                                                                                    />
                                                                                </div>
                                                                            </Link>
                                                                        </TippyText>
                                                                    ))}
                                                                    {item.count_apply <= 3 && item.count_apply > 0 && (
                                                                        <Link to={`${path.DASHBOARD_CV_MANAGEMENT}?campaign_id=${item.id}`}>
                                                                            <div className={cx('item-cv-more')}>Xem chi tiết</div>
                                                                        </Link>
                                                                    )}
                                                                    {item.count_apply > 3 && (
                                                                        <TippyText
                                                                            content={'Xem tất cả'}
                                                                            placement="top"
                                                                            interactive={true}
                                                                            trigger="mouseenter"
                                                                            animation="shift-away"
                                                                            theme="light"
                                                                            delay={[100, 0]}
                                                                        >
                                                                            <Link to={`${path.DASHBOARD_CV_MANAGEMENT}?campaign_id=${item.id}`}>
                                                                                <div className={cx('item-cv-more')}>+{item.count_apply - 3} CV khác</div>
                                                                            </Link>
                                                                        </TippyText>
                                                                    )}
                                                                </div>
                                                            ) : (
                                                                <div className={cx('item-cv')}>Không có CV ứng tuyển mới</div>
                                                            )}
                                                        </div>
                                                    </td>
                                                ) : (
                                                    <td className={cx('table-body-item')}>
                                                        <div className={cx('table-body-item-info')}>
                                                            <div className={cx('info-business')}>
                                                                <div className={cx('business-position')}>
                                                                    <span className={cx('id')}>
                                                                        ID: {item.business.id} - {item.business.full_name}
                                                                    </span>
                                                                    <TippyText content={item.email}>
                                                                        <div className={cx('item-content-info')}>
                                                                            <MdOutlineManageAccounts className={cx('icon')} />
                                                                            <span className={cx('item-content-title')}>{item.business.work_position}</span>
                                                                        </div>
                                                                    </TippyText>
                                                                    <TippyText content={item?.company?.name}>
                                                                        <div className={cx('item-content-title')}> {item?.company?.name.toUpperCase()}</div>
                                                                    </TippyText>
                                                                    <TippyText content={item?.company?.email}>
                                                                        <div className={cx('item-content-info')}>
                                                                            <CgMail className={cx('icon')} />
                                                                            <div className={cx('item-content-des')}>{item?.company?.email}</div>
                                                                        </div>
                                                                    </TippyText>
                                                                    <TippyText content={item?.company?.address}>
                                                                        <div className={cx('item-content-info')}>
                                                                            <GrLocation className={cx('icon')} />
                                                                            <div className={cx('item-content-des')}>{item?.company?.address}</div>
                                                                        </div>
                                                                    </TippyText>
                                                                    <p className={cx('company-apply-number', { active: item?.is_verified })}>
                                                                        {item.is_verified ? 'Đã xác thực' : 'Chưa xác thực'}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                )}
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="6" className={cx('table-body-empty')}>
                                                Không có dữ liệu
                                            </td>
                                        </tr>
                                    )
                                ) : (
                                    Array.from({ length: 6 }).map((_, index) => (
                                        <tr className={cx('table-body-row')} key={index}>
                                            <td colSpan="6" className={cx('table-body-empty')}>
                                                <SkeletonRecruimentCampaignComponent />
                                            </td>
                                        </tr>
                                    ))
                                )}
                            </tbody>
                        </table>
                    </div>

                    {campaigns && campaigns.campaigns?.length > 0 && (
                        <div className={cx('footer')}>
                            <div className={cx('content-footer')}>
                                <span
                                    className={cx('btn', filterCampaign.page === 1 ? 'deactive' : '')}
                                    onClick={handlePrevPage}
                                    disabled={filterCampaign.page === 1}
                                >
                                    <HiOutlineChevronDoubleLeft className={cx('icon')} />
                                </span>
                                <p className={cx('text-page')}>
                                    <span className={cx('number')}>{filterCampaign.page}</span> / {Math.ceil(campaigns.count / 40)} trang
                                </p>
                                <span
                                    className={cx('btn', filterCampaign.page === Math.ceil(campaigns.count / 40) ? 'deactive' : '')}
                                    onClick={handleNextPage}
                                    disabled={filterCampaign.page === Math.ceil(campaigns.count / 10)}
                                >
                                    <HiOutlineChevronDoubleRight className={cx('icon')} />
                                </span>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default RecruitmentCampaignPage;
