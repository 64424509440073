import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const initialState = {
    connected: false,
    wsError: null,
    wsData: null,
    messages: [],
};

const websocketSlide = createSlice({
    name: 'websocket',
    initialState: initialState,
    reducers: {
        setConnected: (state, action) => {
            state.connected = action.payload;
        },
        setWsError: (state, action) => {
            state.wsError = action.payload;
        },
        setWsData: (state, action) => {
            state.wsData = action.payload;
        },
        addMessage: (state, action) => {
            state.messages.push(action.payload);
        },
    },
});

export default websocketSlide.reducer;

export const selectConnected = (state) => state.websocket.connected;
export const selectWsError = (state) => state.websocket.wsError;
export const selectWsData = (state) => state.websocket.wsData;
export const selectMessages = (state) => state.websocket.messages;

export const { setConnected, setWsError, setWsData, addMessage } = websocketSlide.actions;
