import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import classNames from 'classnames/bind';

import { FaCheckCircle } from 'react-icons/fa';
import { VscChevronLeft, VscChevronRight } from 'react-icons/vsc';
import { FaRegCircle } from 'react-icons/fa';

import styles from './SavedJobPage.module.scss';
import { SavedJobComponent } from '@layouts/components/User/SavedJobPage';
import { images } from '@assets';
import { GeneralCompanyFilter } from '@layouts/components/User/JobFilterPage';
import { SkeletonCompanyComponent } from '@components/skeleton';
import route from '@constants/route';
import { getListJobSavesService } from '../../services/user/jobSaveService';

const cx = classNames.bind(styles);

const SavedJobPage = () => {
    const [state, setState] = useState(1);

    const listSort = [
        { id: 1, name: 'Cập nhật gần nhất', value: 'created_at' },
        { id: 2, name: 'Lương cao nhất', value: 'salary' },
    ];

    const [job, setJob] = useState({
        jobs: [],
        total: 0,
        fetchPage: 1,
        loading: true,
    });

    const handleSetFilter = (id) => {
        // setState(id);
        setJob((prev) => ({ ...prev, loading: true, fetchPage: 1 }));
        setState(id);
    };

    const handlePrevPage = () => {
        setJob((prev) => ({ ...prev, fetchPage: prev.fetchPage - 1, loading: true }));
    };

    const handleNextPage = () => {
        setJob((prev) => ({ ...prev, fetchPage: prev.fetchPage + 1, loading: true }));
    };

    useEffect(() => {
        const params = {
            skip: 0,
            limit: 4,
            sort_by: listSort.find((item) => item.id === state).value,
        };

        getListJobSavesService(params)
            .then((res) => {
                if (res.status === 200) {
                    // setJob((prev) => ({ ...prev, jobs: res.data.data.jobs, total: res.data.data.count, loading: false }));
                    setTimeout(() => {
                        setJob((prev) => ({ ...prev, jobs: res.data.data.jobs, total: res.data.data.count, loading: false }));
                    }, 500);
                } else {
                    setJob((prev) => ({ ...prev, loading: false }));
                }
            })
            .catch((err) => {
                setJob((prev) => ({ ...prev, loading: false }));
                console.log(err);
            });
    }, [state]);

    useEffect(() => {
        const params = {
            skip: (job.fetchPage - 1) * 4,
            limit: 4,
            sort_by: listSort.find((item) => item.id === state).value,
        };

        getListJobSavesService(params)
            .then((res) => {
                if (res.status === 200) {
                    // setJob((prev) => ({ ...prev, jobs: res.data.data.jobs, total: res.data.data.count, loading: false }));
                    setTimeout(() => {
                        setJob((prev) => ({ ...prev, jobs: res.data.data.jobs, total: res.data.data.count, loading: false }));
                    }, 500);
                } else {
                    setJob((prev) => ({ ...prev, loading: false }));
                }
            })
            .catch((err) => {
                setJob((prev) => ({ ...prev, loading: false }));
                console.log(err);
            });
    }, [job.fetchPage]);

    return (
        <div className={cx('wrapper')}>
            <div className={cx('container')}>
                <div className={cx('content')}>
                    <div className={cx('banner')}>
                        <div className={cx('content')}>
                            <h1 className={cx('title')}>Việc làm đã lưu</h1>
                            <p className={cx('description')}>
                                Xem lại danh sách những việc làm mà bạn đã lưu trước đó. Ứng tuyển ngay để không bỏ lỡ cơ hội nghề nghiệp dành cho bạn.
                            </p>
                        </div>
                        <div className={cx('image')}>
                            <img className={cx('img')} src={images.arrow_desktop} alt="banner" />
                        </div>
                    </div>
                    {!job.loading && job.jobs.length !== 0 ? (
                        <div className={cx('box-group')}>
                            <div className={cx('box-job-total')}>
                                Danh sách <strong>{job.total}</strong> việc làm đã lưu
                            </div>
                            <div className={cx('box-group-filter')}>
                                <div className={cx('group-filter')}>
                                    <span className={cx('filter-title')}>Ưu tiên hiển thị: </span>

                                    {listSort.map((item, index) => (
                                        <div
                                            key={index}
                                            className={cx('filter-item', state === item.id ? 'active' : '')}
                                            onClick={() => handleSetFilter(item.id)}
                                        >
                                            <FaCheckCircle className={cx('icon', 'icon-active')} />
                                            <FaRegCircle className={cx('icon', 'icon-disabled')} />
                                            <span className={cx('sort')}>{item.name}</span>
                                        </div>
                                    ))}
                                </div>
                            </div>

                            <div className={cx('box-group-body')}>
                                {job.jobs.map((item, index) => (
                                    <div className={cx('box')} key={index}>
                                        <SavedJobComponent job={item.job} setJobs={setJob} />
                                    </div>
                                ))}
                            </div>
                        </div>
                    ) : job.loading ? (
                        <div className={cx('box-group')}>
                            {Array.from({ length: 3 }, (_, index) => (
                                <div className={cx('job', 'skeleton')} key={index}>
                                    <SkeletonCompanyComponent />
                                </div>
                            ))}
                        </div>
                    ) : (
                        <div className={cx('box-group')}>
                            <div className={cx('job', 'empty')}>
                                <img src={images.empty_02} alt="empty" className={cx('img-empty')} />
                                <p className={cx('text')}>Bạn chưa lưu công việc nào!</p>
                                <Link to={route.JOB_SEARCH} className={cx('btn')}>
                                    Tìm việc ngay
                                </Link>
                            </div>
                        </div>
                    )}

                    {!job.loading && job.jobs.length > 0 && (
                        <div className={cx('footer')}>
                            <div className={cx('content-footer')}>
                                <span className={cx('btn', job.fetchPage === 1 ? 'deactive' : '')} onClick={handlePrevPage} disabled={job.fetchPage === 1}>
                                    <VscChevronLeft className={cx('icon')} />
                                </span>
                                <p className={cx('text-page')}>
                                    <span className={cx('number')}>{job.fetchPage}</span> / {Math.ceil(job.total / 4)} trang
                                </p>
                                <span
                                    className={cx('btn', job.fetchPage === Math.ceil(job.total / 4) ? 'deactive' : '')}
                                    onClick={handleNextPage}
                                    disabled={job.fetchPage === Math.ceil(job.total / 4)}
                                >
                                    <VscChevronRight className={cx('icon')} />
                                </span>
                            </div>
                        </div>
                    )}
                </div>
                <div className={cx('sidebar')}>
                    <h3 className={cx('title')}>Có thể bạn quan tâm</h3>
                    <div className={cx('info')}>
                        <GeneralCompanyFilter id={1} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SavedJobPage;
