import classNames from 'classnames/bind';
import PropTypes from 'prop-types';

import styles from './ListAttachmentMessage.module.scss';
import IconAttachmentMessage from './IconAttachmentMessage/IconAttachmentMessage';

const cx = classNames.bind(styles);

const ListAttachmentMessage = ({ attachments, removeAttachment, isUploading }) => {
    return (
        <div className={cx('wrapper')}>
            {attachments.map(
                (attachment, index) =>
                    !attachment.isRemove && (
                        <IconAttachmentMessage
                            key={index}
                            attachment={attachment}
                            attachments={attachments}
                            removeAttachment={removeAttachment}
                            isUploading={isUploading}
                        />
                    ),
            )}
        </div>
    );
};

ListAttachmentMessage.propTypes = {
    attachments: PropTypes.array,
    removeAttachment: PropTypes.func,
    isUploading: PropTypes.bool,
};

export default ListAttachmentMessage;
