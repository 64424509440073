import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import TippyText from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

import { FaPen } from 'react-icons/fa';
import { BsThreeDots } from 'react-icons/bs';
import { CgMail } from 'react-icons/cg';
import { FiPhone } from 'react-icons/fi';
import { MdOutlineManageAccounts } from 'react-icons/md';

import styles from './CVRowComponent.module.scss';
import { images } from '../../assets';
import path from '../../constants/path';
import { Link } from 'react-router-dom';
import { cVStatus } from '../../constants';
import { hideCV, showCV } from '../../redux/features/cvDetail/cvDetailSlice';
import { showModal } from '../../redux/features/modal/modalSlice';
import { updateCVApplicationService, viewCVApplicationService } from '../../services/business/businessCVApplicationsService';
import Modal from '../common/Modal/Modal';
import CVDetailComponent from '../CVDetailComponent/CVDetailComponent';

const cx = classNames.bind(styles);

const CVRowComponent = ({ cv }) => {
    const dispatch = useDispatch();
    const menuRef = useRef(null);
    const [showMenu, setShowMenu] = useState(false);
    const [status, setStatus] = useState(cv.status);
    const [views, setViews] = useState(cv.count_view || 0);

    const showCVDetail = () => {
        dispatch(showCV(cv));
        dispatch(showModal());
        handleRequestStatus('viewed');
        handleViewCV();

        setShowMenu(false);
    };

    const handleRequestStatus = (new_status) => {
        const body = {
            status: new_status,
        };

        updateCVApplicationService(cv.id, body)
            .then((res) => {
                if (res.status === 200) {
                    if (new_status === 'viewed') {
                        if (status === 'pending') {
                            setStatus('viewed');
                        }
                    } else {
                        setStatus(new_status);
                    }
                }
            })
            .catch((err) => {
                console.log(err);
            });

        setShowMenu(false);
    };

    const handleViewCV = () => {
        viewCVApplicationService(cv.id)
            .then((res) => {
                if (res.status === 204) {
                    setViews((prev) => prev + 1);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const showSelect = () => {
        setShowMenu(!showMenu);
    };

    const statusUpdate = [
        {
            id: 2,
            value: 'Chấp nhận',
            handle: () => handleRequestStatus('approved'),
        },
        {
            id: 1,
            value: 'Từ chối',
            handle: () => handleRequestStatus('rejected'),
        },
        {
            id: 3,
            value: 'Phỏng vấn',
            handle: () => handleRequestStatus('interview'),
        },
        {
            id: 4,
            value: 'Xem chi tiết',
            handle: showCVDetail,
        },
    ];

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setShowMenu(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <tr className={cx('table-body-row')}>
            <Modal>
                <CVDetailComponent />
            </Modal>
            <td className={cx('table-body-item')}>
                <div className={cx('item-avatar')}>
                    <div className={cx('item-content')}>
                        <div className={cx('item-content-image')}>
                            <img src={cv.user.avatar || images.avatar_default} alt={cv.full_name} className={cx('avatar')} />
                        </div>
                    </div>
                </div>
            </td>
            <td className={cx('table-body-item')}>
                <div className={cx('item-name')}>
                    <span className={cx('full_name')}>
                        <Link to={path.DASHBOARD_SUGGESTION} className={cx('full-name-link')}>
                            {cv.full_name}
                        </Link>
                    </span>
                    {
                        <span className={cx('status')}>
                            {views > 0 ? <span className={cx('count-view')}>Đã xem: {views}</span> : <span className={cx('count-view')}>Chưa xem</span>}
                        </span>
                    }
                </div>
            </td>
            <td className={cx('table-body-item')}>
                <div className={cx('table-body-item-info')}>
                    <div className={cx('info-business')}>
                        <div className={cx('business-position')}>
                            <span className={cx('id')}>
                                ID: {cv.user.id} - {cv.user.role}
                            </span>
                            <TippyText content={cv.email}>
                                <div className={cx('item-content-info')}>
                                    <CgMail className={cx('icon')} />
                                    <span className={cx('item-content-title')}>{cv.email}</span>
                                </div>
                            </TippyText>
                            <TippyText content={cv.phone_number}>
                                <div className={cx('item-content-info')}>
                                    <FiPhone className={cx('icon')} />
                                    <span className={cx('item-content-title')}>{cv.phone_number}</span>
                                </div>
                            </TippyText>
                        </div>
                    </div>
                </div>
            </td>
            <td className={cx('table-body-item')}>
                <div className={cx('table-body-item-campaign')}>
                    <span className={cx('item-content-title')}>{cv.campaign?.title}</span>
                    <span className={cx('item-content-id')}>#{cv.job?.campaign_id}</span>
                </div>
            </td>
            <td className={cx('table-body-item')}>
                <div className={cx('table-body-item-status')}>
                    <span className={cx('item-content-status', `item-content-status-${status}`)}>{cVStatus.find((item) => item.value === status)?.name}</span>
                    <span className={cx('item-content-update-status')}>
                        <BsThreeDots className={cx('icon')} onClick={showSelect} />
                        <div className={cx('item-content-update-status-list', { show: showMenu })} ref={menuRef}>
                            {statusUpdate.map((item) => (
                                <div key={item.id} className={cx('item-content-update-status-item')} onClick={item.handle}>
                                    <span className={cx('item-content-update-status-item-value')}>{item.value}</span>
                                </div>
                            ))}
                        </div>
                    </span>
                </div>
            </td>
        </tr>
    );
};

CVRowComponent.propTypes = {
    cv: PropTypes.object.isRequired,
};

export default CVRowComponent;
