import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    show: false,
    cv: {},
};

const cvDetailSlice = createSlice({
    name: 'cvDetail',
    initialState,
    reducers: {
        showCV: (state, action) => {
            state.show = true;
            state.cv = action.payload;
        },
        hideCV: (state) => {
            state.show = false;
            state.cv = {};
        },
    },
});
export default cvDetailSlice.reducer;

export const selectCV = (state) => state.cvDetail.cv;
export const selectShowCV = (state) => state.cvDetail.show;

export const { showCV, hideCV } = cvDetailSlice.actions;
