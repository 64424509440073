import * as request from '@utils/axios';

export const getListJobSavesService = async (params) => {
    const response = await request.apiAuth.get('/user/job_save', { params });
    return response;
};

export const createJobSaveService = async (job_id) => {
    const response = await request.apiAuth.post(`/user/job_save/${job_id}`);
    return response;
};

export const deleteJobSaveService = async (job_id) => {
    const response = await request.apiAuth.delete(`/user/job_save/${job_id}`);
    return response;
};
