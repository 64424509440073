import React, { useEffect, useRef, useState } from 'react';
import Chart from 'chart.js/auto';
import classNames from 'classnames/bind';

import styles from './ChartJobOpportunity.module.scss';

const cx = classNames.bind(styles);

const ChartJobOpportunity = () => {
    const fakeOpportunity = [
        { key: '01/01/2025', value: 14 },
        { key: '02/01/2025', value: 17 },
        { key: '03/01/2025', value: 12 },
        { key: '04/01/2025', value: 15 },
        { key: '05/01/2025', value: 18 },
        { key: '06/01/2025', value: 16 },
        { key: '07/01/2025', value: 11 },
        { key: '08/01/2025', value: 13 },
        { key: '09/01/2025', value: 19 },
        { key: '10/01/2025', value: 14 },
        { key: '11/01/2025', value: 17 },
        { key: '12/01/2025', value: 16 },
        { key: '13/01/2025', value: 20 },
        { key: '14/01/2025', value: 10 },
        { key: '15/01/2025', value: 18 },
        { key: '16/01/2025', value: 12 },
        { key: '17/01/2025', value: 15 },
        { key: '18/01/2025', value: 13 },
        { key: '19/01/2025', value: 14 },
        { key: '20/01/2025', value: 17 },
    ];
    const labels = fakeOpportunity.map((item) => item.key.split('/')[0] + '/' + item.key.split('/')[1]);
    const dataNumber = fakeOpportunity.map((item) => {
        return item.value;
    });
    const chartRef = useRef(null);

    const [inputData, setInputData] = useState(labels);

    useEffect(() => {
        const data = {
            labels: inputData,
            datasets: [
                {
                    data: dataNumber,
                    borderColor: 'rgb(17, 215, 105)',
                    borderWidth: 3,
                    pointRadius: 0,
                },
            ],
        };

        const config = {
            type: 'line',
            data: data,
            options: {
                plugins: {
                    legend: {
                        display: false,
                    },

                    tooltip: {
                        callbacks: {
                            label: (context) => context.formattedValue + ' việc làm',
                            title: (context) => context[0].label,
                        },
                        titleFont: { size: 14, weight: 500 },
                        bodyFont: { size: 18, weight: 600 },
                        displayColors: false,
                    },
                },
                interaction: {
                    mode: 'nearest',
                    intersect: false,
                    axis: 'x',
                },
                scales: {
                    x: {
                        grid: {
                            display: false,
                        },
                        ticks: {
                            display: true,
                            beginAtZero: true,
                            color: '#fff',
                            font: {
                                size: 11,
                                weight: 500,
                                angle: 45,
                            },
                            maxTicksLimit: 6,
                            padding: 3,
                            maxRotation: 45,
                            minRotation: 45,
                        },
                    },
                    y: {
                        ticks: {
                            display: true,
                            beginAtZero: true,
                            color: '#fff',
                            font: {
                                size: 10,
                                weight: 500,
                            },
                            maxTicksLimit: 6,
                        },
                        grid: {
                            color: 'rgba(28, 200, 200, 0.1)',
                            lineWidth: 2,
                            tickLength: 10,
                            tickWidth: 0,
                            tickBorderDash: [10, 4],
                        },
                        border: {
                            display: false,
                            dash: [15, 10],
                            dashOffset: 100,
                        },
                    },
                },
                elements: {
                    pointStyle: 'circle',
                },
            },
        };

        if (chartRef && chartRef.current) {
            const chart = new Chart(chartRef.current, config);

            return () => {
                chart.destroy();
            };
        }
    }, [inputData]);

    useEffect(() => {
        const checkWidth = () => {
            if (window.innerWidth < 768) {
                chartRef.current.style.width = '320px';
                chartRef.current.style.height = '220px';
            } else {
                chartRef.current.style.width = '352px';
                chartRef.current.style.height = '220px';
            }
        };
        checkWidth();
        window.addEventListener('resize', checkWidth);
        return () => {
            window.removeEventListener('resize', checkWidth);
        };
    }, []);

    return (
        <div className={cx('wrapper')}>
            <div className={cx('container')}>
                <canvas ref={chartRef} height="220" width={'352'} className={cx('canvas')} style={{ width: '352px', height: '220px' }}></canvas>
            </div>
        </div>
    );
};

export default ChartJobOpportunity;
