import * as request from '@utils/axios';

export const getCVApplicationService = async (id) => {
    const response = await request.apiBusinessAuth.get(`/business/cv_applications/${id}`);
    return response;
};

export const getListCVApplicationsService = async (params) => {
    const response = await request.apiBusinessAuth.get('/business/cv_applications', { params });
    return response;
};

export const updateCVApplicationService = async (id, data) => {
    const response = await request.apiBusinessAuth.put(`/business/cv_applications/${id}`, data);
    return response;
};

export const viewCVApplicationService = async (id) => {
    const response = await request.apiBusinessAuth.put(`/business/cv_applications/${id}/view`);
    return response;
};
