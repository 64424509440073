import { useEffect, useState } from 'react';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames/bind';
import TippyText from '@tippyjs/react';
import 'tippy.js/dist/backdrop.css';
import 'tippy.js/dist/svg-arrow.css';
import 'tippy.js/animations/shift-away.css';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import { IoInformationCircleOutline } from 'react-icons/io5';
import { FaWandMagicSparkles, FaCircleInfo, FaMinus, FaPlus } from 'react-icons/fa6';
import { FaRegCalendar } from 'react-icons/fa';

import styles from './DashboardPreviewJobPage.module.scss';
import { icons } from '@assets';
import path from '@constants/path';
import { InputSelectorComponent, InputSelectorMultiComponent } from '@components/common';
import { selectPostJob, setCampaignId } from '@redux/features/postJob/postJobSlide';
import { JobPreviewLocation, JobGeneralRequirements, JobDetailRequirements, JobInfoContact } from '@layouts/components/Business/PostJobPage';
import { getCampaignByIdService } from '@services/business/campaignService';
import { getListJobPositionService } from '@services/common/positionService';
import { getListCategoryService } from '@services/common/categoryService';
import useToast from '@hooks/useToast';
import { selectCategory, selectJobPosition, setCategory, setJobPosition } from '@redux/features/config/configSilde';
import { getBusinessJobSerivce, updateStatusBusinessJobService } from '../../../services/business/businessJobService';
import { setPostJob } from '../../../redux/features/postJob/postJobSlide';
import { approvalRequestJobService, updateApprovalRequestJobService } from '../../../services/business/adminApprovalRequestJjobService';
import { groupRole, role } from '../../../constants';
import { selectUser, selectUserRole } from '../../../redux/features/authUser/authSlide';
import { SkeletonJobDashboardComponent } from '../../../components/skeleton';

const cx = classNames.bind(styles);

const DashboardPreviewJobPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const info = useSelector(selectPostJob);
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const campaign_id = query.get('campaign_id');
    const { id } = useParams();
    const user = useSelector(selectUser);
    const userRole = useSelector(selectUserRole);

    const jobPositions = useSelector(selectJobPosition);
    const jobCategories = useSelector(selectCategory);
    const job = useSelector(selectPostJob);

    const [jobApprovalRequestId, setJobApprovalRequestId] = useState('');
    const [jobLoading, setJobLoading] = useState(true);
    const [loading, setLoading] = useState({
        post: false,
        reject: false,
        stop: false,
        update: false,
    });
    const [isLoadPosition, setIsLoadPosition] = useState(jobPositions ? false : true);
    const [isLoadCategories, setIsLoadCategories] = useState(jobCategories ? false : true);
    const { handleAddToast } = useToast();
    const [rejectHidden, setRejectHidden] = useState(true);
    const [reason, setReason] = useState('');
    const [jobLogs, setJobLogs] = useState([]);
    const [jobStatus, setJobStatus] = useState('');

    const handleApprove = () => {
        setLoading({ ...loading, post: false });
        const body = {
            status: 'approved',
        };
        jobStatus !== 'pending'
            ? updateApprovalRequestJobService(jobApprovalRequestId, body)
            : approvalRequestJobService(jobApprovalRequestId, body)
                  .then((res) => {
                      if (res.status === 200) {
                          handleAddToast('Thành công', 'Duyệt tin thành công', 'success');
                          navigate(path.DASHBOARD_RECRUIREMENT_CAMPAIGNS);
                      } else if (res.status === 400) {
                          handleAddToast('Cảnh báo', 'Dữ liệu không hợp lệ', 'warning');
                          setLoading({ ...loading, post: false });
                      } else if (res.status === 403) {
                          handleAddToast('Cảnh báo', 'Không có quyền thực hiện hành động này', 'warning');
                          setLoading({ ...loading, post: false });
                      } else if (res.status === 404) {
                          handleAddToast('Cảnh báo', 'Không tìm thấy dữ liệu', 'warning');
                          setLoading({ ...loading, post: false });
                      } else if (res.status === 500) {
                          handleAddToast('Cảnh báo', 'Đã có lỗi xảy ra', 'warning');
                          setLoading({ ...loading, post: false });
                      }
                  })
                  .catch((err) => {
                      console.log(err);
                      handleAddToast('Cảnh báo', 'Đã có lỗi xảy ra', 'warning');
                      setLoading({ ...loading, post: false });
                  });
    };

    const handleReject = () => {
        setLoading({ ...loading, reject: false, stop: false });
        const body = {
            status: jobStatus === 'pending' ? 'rejected' : 'stopped',
            reason: reason,
        };
        jobStatus !== 'pending'
            ? updateApprovalRequestJobService(jobApprovalRequestId, body)
            : approvalRequestJobService(jobApprovalRequestId, body)
                  .then((res) => {
                      if (res.status === 200) {
                          handleAddToast('Thành công', jobStatus === 'pending' ? 'Từ chối tin thành công' : 'Hạ tin thành công', 'success');
                          navigate(path.DASHBOARD_RECRUIREMENT_CAMPAIGNS);
                      } else if (res.status === 400) {
                          handleAddToast('Cảnh báo', 'Dữ liệu không hợp lệ', 'warning');
                          setLoading({ ...loading, reject: false, stop: false });
                      } else if (res.status === 403) {
                          handleAddToast('Cảnh báo', 'Không có quyền thực hiện hành động này', 'warning');
                          setLoading({ ...loading, reject: false, stop: false });
                      } else if (res.status === 404) {
                          handleAddToast('Cảnh báo', 'Không tìm thấy dữ liệu', 'warning');
                          setLoading({ ...loading, reject: false, stop: false });
                      } else if (res.status === 500) {
                          handleAddToast('Cảnh báo', 'Đã có lỗi xảy ra', 'warning');
                          setLoading({ ...loading, reject: false, stop: false });
                      }
                  })
                  .catch((err) => {
                      console.log(err);
                      handleAddToast('Cảnh báo', 'Đã có lỗi xảy ra', 'warning');
                      setLoading({ ...loading, reject: false, stop: false });
                  });
    };

    const updateStatus = () => {
        const body = {
            status: jobStatus === 'pending' || jobStatus === 'published' ? 'stopped' : 'published',
        };
        updateStatusBusinessJobService(job.id, body)
            .then((res) => {
                if (res.status === 200) {
                    handleAddToast(
                        'Thành công',
                        jobStatus === 'pending' || jobStatus === 'published' ? 'Hạ tin thành công' : 'Yêu cầu duyệt tin thành công',
                        'success',
                    );
                    navigate(path.DASHBOARD_RECRUIREMENT_CAMPAIGNS);
                } else if (res.status === 400) {
                    handleAddToast('Cảnh báo', 'Dữ liệu không hợp lệ', 'warning');
                    setLoading({ ...loading, update: false });
                } else if (res.status === 403) {
                    handleAddToast('Cảnh báo', 'Không có quyền thực hiện hành động này', 'warning');
                    setLoading({ ...loading, update: false });
                } else if (res.status === 404) {
                    handleAddToast('Cảnh báo', 'Không tìm thấy dữ liệu', 'warning');
                    setLoading({ ...loading, update: false });
                } else if (res.status === 500) {
                    handleAddToast('Cảnh báo', 'Đã có lỗi xảy ra', 'warning');
                    setLoading({ ...loading, update: false });
                }
            })
            .catch((err) => {
                console.log(err);
                handleAddToast('Cảnh báo', 'Đã có lỗi xảy ra', 'warning');
                setLoading({ ...loading, update: false });
            });
    };

    const handleUpdateStatus = () => {
        handleAddToast('Thông báo', 'Đang xử lý, vui lòng chờ trong giây lát', 'info');
        setLoading({ ...loading, update: true });
    };

    const handleActiveReject = () => {
        setRejectHidden(!rejectHidden);
    };

    const handleActiveStop = () => {
        setRejectHidden(true);
    };

    const handleSetPost = () => {
        handleAddToast('Thông báo', 'Đang xử lý, vui lòng chờ trong giây lát', 'info');
        setLoading({ ...loading, post: true });
    };

    const handSetReject = () => {
        handleAddToast('Thông báo', 'Đang xử lý, vui lòng chờ trong giây lát', 'info');
        setLoading({ ...loading, reject: true });
    };

    const handleStopJob = () => {
        handleAddToast('Thông báo', 'Đang xử lý, vui lòng chờ trong giây lát', 'info');
        setLoading({ ...loading, stop: true });
    };

    useEffect(() => {
        if (campaign_id) {
            getCampaignByIdService(campaign_id)
                .then((res) => {
                    if (res.status === 200) {
                        dispatch(setCampaignId(res.data.data.id));
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }, [campaign_id]);

    useEffect(() => {
        !jobPositions &&
            getListJobPositionService()
                .then((res) => {
                    if (res.status === 200) {
                        dispatch(setJobPosition(res.data.data));
                        setIsLoadPosition(false);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });

        !jobCategories &&
            getListCategoryService()
                .then((res) => {
                    if (res.status === 200) {
                        dispatch(setCategory(res.data.data));
                        setIsLoadCategories(false);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
    }, []);

    useEffect(() => {
        loading.post && handleApprove();
    }, [loading.post]);

    useEffect(() => {
        loading.reject && handleReject();
    }, [loading.reject]);

    useEffect(() => {
        loading.stop && handleReject();
    }, [loading.stop]);

    useEffect(() => {
        loading.update && updateStatus();
    }, [loading.update]);

    useEffect(() => {
        getBusinessJobSerivce(id)
            .then((res) => {
                if (res.status === 200) {
                    const data = res.data.data;
                    dispatch(setPostJob(data));
                    setJobApprovalRequestId(data?.last_approval_request.id);
                    setJobLogs(data.job_logs);
                    setJobStatus(data.status);
                    setJobLoading(false);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    return job && !jobLoading ? (
        <div className={cx('wrapper')}>
            <div className={cx('auth-modal')}></div>
            <div className={cx('container')}>
                <div className={cx('breadcrumb-box')}>
                    <div className={cx('breadcrumb-box-title')}>
                        <h6 className={cx('breadcrumb')}>Đăng tin tuyển dụng</h6>
                        <div className={cx('breadcrumb-box-subtitle')}>
                            <IoInformationCircleOutline className={cx('icon')} />
                            <a href={path.DASHBOARD_HOME} className={cx('breadcrumb-link')}>
                                Quy định đăng tin
                            </a>
                        </div>
                    </div>
                    <div className={cx('breadcrumb-box-button', { show: jobStatus === 'rejected', pending: jobStatus === 'pending' })}>
                        <div className={cx('list-button')}>
                            {jobStatus === 'rejected' && (
                                <div className={cx('group-button')}>
                                    <button className={cx('button', 'button-rejected')} disabled={true}>
                                        Đã từ chối
                                    </button>
                                    {userRole !== role.BUSINESS && (
                                        <button className={cx('button', 'button-save-back')} onClick={() => navigate(path.DASHBOARD_RECRUIREMENT_CAMPAIGNS)}>
                                            Quay lại
                                        </button>
                                    )}
                                </div>
                            )}
                            {/* {jobStatus === 'pending' && userRole === role.BUSINESS && (
                                <div className={cx('group-button')}>
                                    <button
                                        className={cx('button', 'button-save-reject-stopped')}
                                        disabled={loading.post || loading.reject || loading.stop}
                                        onClick={handleStopJob}
                                        style={{ display: 'block' }}
                                    >
                                        Hạ tin
                                    </button>
                                    <button
                                        className={cx('button', 'button-save-back-stopped')}
                                        onClick={() => navigate(path.DASHBOARD_RECRUIREMENT_CAMPAIGNS)}
                                        style={{ display: 'block' }}
                                    >
                                        Quay lại
                                    </button>
                                </div>
                            )} */}
                            {jobStatus === 'stopped' && userRole === role.BUSINESS && (
                                <div className={cx('group-button')}>
                                    <button className={cx('button', 'button-save-back-stopped')} onClick={handleUpdateStatus}>
                                        Yêu cầu duyệt
                                    </button>
                                </div>
                            )}

                            {jobStatus === 'published' && (
                                <div className={cx('group-button')}>
                                    <button
                                        className={cx('button', 'button-save-reject')}
                                        disabled={loading.post || loading.reject || loading.stop}
                                        onClick={handleStopJob}
                                    >
                                        Hạ tin
                                    </button>
                                    <button
                                        className={cx('button', 'button-save-back')}
                                        onClick={() => navigate(path.DASHBOARD_RECRUIREMENT_CAMPAIGNS)}
                                        style={{ display: 'block' }}
                                    >
                                        Quay lại
                                    </button>
                                </div>
                            )}
                            {groupRole.BUSINESS.includes(userRole) && (
                                <div className={cx('group-button')}>
                                    <button className={cx('button', 'button-pending')} disabled={true}>
                                        Đang chờ duyệt
                                    </button>
                                    {jobStatus === 'pending' && (
                                        <button
                                            className={cx('button', 'button-save-reject-stopped')}
                                            disabled={loading.post || loading.reject || loading.stop}
                                            onClick={handleUpdateStatus}
                                            style={{ display: 'block' }}
                                        >
                                            Hủy yêu cầu
                                        </button>
                                    )}
                                    <button className={cx('button', 'button-save-back')} onClick={() => navigate(path.DASHBOARD_RECRUIREMENT_CAMPAIGNS)}>
                                        Quay lại
                                    </button>
                                </div>
                            )}
                            {groupRole.ADMIN.includes(userRole) && jobStatus === 'pending' && (
                                <div className={cx('group-button')}>
                                    <button
                                        className={cx('button', 'button-save-reject')}
                                        disabled={loading.post || loading.reject}
                                        onClick={handleActiveReject}
                                    >
                                        Từ chối
                                    </button>
                                    <button className={cx('button', 'button-post')} onClick={handleSetPost} disabled={loading.post || loading.reject}>
                                        Duyệt tin
                                    </button>
                                </div>
                            )}
                            <div className={cx('reject-wrapper', { hidden: rejectHidden })}>
                                <textarea
                                    className={cx('reject')}
                                    placeholder="Lý do từ chối"
                                    value={jobStatus === 'rejected' ? job.job_logs[0].reason : reason}
                                    onChange={(e) => setReason(e.target.value)}
                                    disabled={jobStatus === 'rejected'}
                                ></textarea>
                                <button className={cx('button', 'button-reject')} disabled={loading.post || loading.reject} onClick={handSetReject}>
                                    Xác nhận
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={cx('content')}>
                    <div className={cx('content-list')}>
                        <div className={cx('box-content')}>
                            <div className={cx('box-content-item')}>
                                <div className={cx('item-title')}>
                                    <h6 className={cx('title')}>Thông tin chung</h6>
                                </div>
                                <div className={cx('item-content')}>
                                    <div>
                                        <div className={cx('box-content-group')}>
                                            <label className={cx('label')} htmlFor="job-title">
                                                Tiêu đề tin
                                            </label>
                                            <div className={cx('input-box')}>
                                                <div className={cx('input-box-item')}>
                                                    <input
                                                        type="text"
                                                        id="job-title"
                                                        name="job-title"
                                                        className={cx('input')}
                                                        value={job.title}
                                                        disabled={true}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className={cx('box-content-group')}>
                                            <label className={cx('label', 'label-secondary')} htmlFor="job-title-show">
                                                Tiêu đề hiển thị trên
                                                <img src={icons.icon_logo_text} alt="logo-text" className={cx('icon-logo-text')} />
                                            </label>
                                            <div className={cx('box-content-flex')}>
                                                <div className={cx('box-content-job-type')}>
                                                    <div className={cx('job-type')}>
                                                        <div className={cx('job-type-icon')}>
                                                            <img src={icons.icon_check_mark} alt="check-mark" className={cx('icon-check-mark')} />
                                                        </div>
                                                        <div className={cx('job-type-title')}>Tin cơ bản</div>
                                                        <div className={cx('job-type-text')}>
                                                            <label className={cx('label')} htmlFor="normal">
                                                                <span className={cx('text')}>{job.title.substring(0, 50)}</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className={cx('job-type-description')}>
                                                        <p className={cx('description')}>
                                                            <span>
                                                                Tiêu đề giới hạn
                                                                <b className={cx('description-bold')}> 50 ký tự </b>
                                                                và không chứa các từ khóa liên quan đến
                                                                <b className={cx('description-bold')}> thu nhập </b>
                                                                hoặc
                                                                <b className={cx('description-bold')}> địa điểm </b>.
                                                            </span>
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className={cx('box-content-job-type')}>
                                                    <div className={cx('job-type')}>
                                                        <div className={cx('job-type-title', 'job-top')}>
                                                            <FaWandMagicSparkles className={cx('icon-magic')} />
                                                            Tin Now Jobs
                                                        </div>
                                                        <div className={cx('job-type-text')}>
                                                            <label className={cx('label')} htmlFor="nơ-job">
                                                                <span className={cx('text', 'highlight')}>{job.title}</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className={cx('job-type-description')}>
                                                        <p className={cx('description')}>
                                                            <span>
                                                                Tiêu đề có thể dài tới
                                                                <b className={cx('description-bold')}> 255 ký tự </b>.
                                                            </span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={cx('box-content-flex')}>
                                        <div className={cx('box-content-group')}>
                                            <label className={cx('label')} htmlFor="job-position">
                                                Vị trí tuyển dụng
                                                <TippyText content="Thông tin này sẽ giúp gợi ý ứng viên phù hợp chính xác hơn" placement="top">
                                                    <span className={cx('tooltip')}>
                                                        <FaCircleInfo className={cx('icon-info')} />
                                                    </span>
                                                </TippyText>
                                            </label>
                                            <div className={cx('input-box')}>
                                                {!isLoadPosition && job.job_position_id ? (
                                                    <InputSelectorComponent
                                                        disabled={true}
                                                        defaultValue={job.job_location}
                                                        placeholder={'VD: Nhân viên Marketing, Designer, ...'}
                                                        styleInput={{ paddingTop: '7px', paddingBottom: '7px' }}
                                                    />
                                                ) : (
                                                    <div className={cx('spinner')}>
                                                        <Skeleton width={300} height={33.5} />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className={cx('box-content-group')}>
                                            <label className={cx('label')} htmlFor="job-type">
                                                Ngành nghề
                                            </label>
                                            <div className={cx('input-box')}>
                                                {!isLoadCategories ? (
                                                    <InputSelectorMultiComponent
                                                        options={jobCategories}
                                                        value={job.categories}
                                                        disabled={true}
                                                        styleInput={{ paddingTop: '7px', paddingBottom: '7px' }}
                                                    />
                                                ) : (
                                                    <div className={cx('spinner')}>
                                                        <Skeleton width={300} height={33.5} />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={cx('box-content-flex')}>
                                        <div className={cx('box-content-group')}>
                                            <label className={cx('label')} htmlFor="deadline">
                                                Hạn nộp hồ sơ
                                                <span className={cx('required')}>*</span>
                                            </label>
                                            <div className={cx('input-box')}>
                                                <div className={cx('input-box-item', 'input-date')}>
                                                    <FaRegCalendar className={cx('icon-calendar')} />
                                                    <input
                                                        type="date"
                                                        id="deadline"
                                                        name="deadline"
                                                        className={cx('date')}
                                                        value={info.deadline}
                                                        disabled={true}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className={cx('box-content-group')}>
                                            <label className={cx('label')} htmlFor="job-quantity">
                                                Số lượng tuyển
                                            </label>
                                            <div className={cx('input-box')}>
                                                <div className={cx('input-box-item', 'input-quantity')}>
                                                    <button className={cx('action', 'action-sub')}>
                                                        <FaMinus className={cx('icon-action', 'icon-minus')} />
                                                    </button>
                                                    <input
                                                        type="text"
                                                        id="job-quantity"
                                                        name="job-quantity"
                                                        className={cx('input')}
                                                        value={info.quantity}
                                                        disabled={true}
                                                    />
                                                    <button className={cx('action', 'action-add')}>
                                                        <FaPlus className={cx('icon-action', 'icon-plus')} />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={cx('box-content-group')}>
                                        <label className={cx('label')} htmlFor="location">
                                            Khu vực làm việc
                                        </label>
                                        <div className={cx('select-box')}>
                                            <div>
                                                {job.locations.map((item, index) => (
                                                    <JobPreviewLocation key={index} location={item} />
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={cx('box-content-item')}>
                                <JobGeneralRequirements disabled={true} />
                            </div>
                            <div className={cx('box-content-item')}>
                                <JobDetailRequirements disabled={true} />
                            </div>
                            <div className={cx('box-content-item')}>
                                <JobInfoContact disabled={true} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    ) : (
        <SkeletonJobDashboardComponent />
    );
};

export default DashboardPreviewJobPage;
