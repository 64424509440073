import * as request from '@utils/axios';

export const getApprovalRequestJobService = async (params) => {
    const response = await request.apiBusinessAuth.get('/admin/approval_request_job', { params });
    return response;
};

export const getApprovalRequestJobDetailService = async (id) => {
    const response = await request.apiBusinessAuth.get(`/admin/approval_request_job/${id}`);
    return response;
};

export const approvalRequestJobService = async (id, body) => {
    const response = await request.apiBusinessAuth.put(`/admin/approval_request_job/${id}/approve`, body);
    return response;
};

export const updateApprovalRequestJobService = async (id, body) => {
    const response = await request.apiBusinessAuth.put(`/admin/approval_request_job/${id}/job`, body);
    return response;
};
