import classNames from 'classnames/bind';
import PropTypes from 'prop-types';

import styles from './JobPreviewDistrict.module.scss';
import { InputSelectorComponent } from '@components/common';

const cx = classNames.bind(styles);

const JobPreviewDistrict = ({ district, description }) => {
    return (
        <div className={cx('wrapper')}>
            <div className={cx('container')}>
                <div className={cx('district')}>
                    <InputSelectorComponent options={[]} defaultValue={district} disabled={true} />
                </div>

                <div className={cx('input')}>
                    <input className={cx('input-text')} disabled={true} type="text" value={description} />
                </div>
            </div>
        </div>
    );
};

JobPreviewDistrict.propTypes = {
    district: PropTypes.number,
    description: PropTypes.string,
};

export default JobPreviewDistrict;
