import classNames from 'classnames/bind';
import PropTypes from 'prop-types';

import { convertDateTime } from '@utils/convert/convertTimeUtil';

import styles from './CVDetailComponent.module.scss';
import { useSelector } from 'react-redux';
import { selectCV, selectShowCV } from '../../redux/features/cvDetail/cvDetailSlice';

const cx = classNames.bind(styles);

const CVDetailComponent = () => {
    const cv = useSelector(selectCV);
    const isShow = useSelector(selectShowCV);

    return (
        <div className={cx('wrapper')}>
            {isShow && (
                <div className={cx('container')}>
                    <div className={cx('header')}>
                        <div className={cx('header-title')}>
                            <h2 className={cx('title')}>Thông tin cá nhân</h2>
                        </div>
                    </div>
                    <div className={cx('content')}>
                        <div className={cx('content-info')}>
                            <div className={cx('info')}>
                                <p className={cx('info-title')}>Họ và tên:</p>
                                <p className={cx('info-value')}>{cv.full_name}</p>
                            </div>
                            <div className={cx('Email')}>
                                <p className={cx('info-title')}>Email:</p>
                                <p className={cx('info-value')}>{cv.email}</p>
                            </div>
                            <div className={cx('phone')}>
                                <p className={cx('info-title')}>Số điện thoại:</p>
                                <p className={cx('info-value')}>{cv.phone_number}</p>
                            </div>
                            <div className={cx('CV')}>
                                <p className={cx('info-title')}>CV:</p>
                                <a href={cv.cv} target="_blank" rel="noreferrer" className={cx('info-value')}>
                                    {cv.name}
                                </a>
                            </div>
                            <div className={cx('time')}>
                                <p className={cx('info-title')}>Thời gian: </p>
                                <p className={cx('info-value')}>{convertDateTime(cv.created_at)}</p>
                            </div>
                            <div className={cx('cover-letter')}>
                                <p className={cx('info-title')}>Cover letter:</p>
                                <p className={cx('info-value')}>{cv.cover_letter}</p>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

CVDetailComponent.propTypes = {};

export default CVDetailComponent;
