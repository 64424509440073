import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import 'react-loading-skeleton/dist/skeleton.css';

import { FaLocationDot } from 'react-icons/fa6';

import styles from './JobPreviewLocation2.module.scss';
import InputSelectorComponent from '../../../../../components/common/InputSelectorComponent/InputSelectorComponent';
import JobPreviewDistrict from '../JobPreviewDistrict/JobPreviewDistrict';
import { useSelector } from 'react-redux';
import { selectProvince } from '../../../../../redux/features/config/configSilde';
import { useEffect, useState } from 'react';
import { getDistrictService } from '../../../../../services/common/locationService';

const cx = classNames.bind(styles);

const JobPreviewLocation2 = ({ location }) => {
    const province = useSelector(selectProvince);
    const [district, setDistrict] = useState(null);

    console.log('location', location);
    console.log('province', province);

    useEffect(() => {
        if (location?.district_id > 0) {
            getDistrictService(location.district_id)
                .then((res) => {
                    setDistrict(res.data);
                })
                .catch((err) => {
                    console.log('err', err);
                });
        }
    }, [location]);

    return (
        <div className={cx('wrapper')}>
            <div className={cx('container')}>
                <div className={cx('location-box')}>
                    <div className={cx('location-box__province')}>
                        <div className={cx('location-box__province-title')}>
                            <FaLocationDot className={cx('title-icon')} />
                            <span className={cx('title-text')}>Khu vực:</span>
                        </div>
                        <div className={cx('location-box__province-content')}>
                            <InputSelectorComponent
                                defaultValue={province.find((item) => item.id === location?.province_id)?.name_with_type}
                                options={[]}
                                disabled={true}
                            />
                        </div>
                    </div>
                </div>
                {district > 0 && (
                    <div className={cx('address-box')}>
                        <div className={cx('address-box__district')}>
                            <JobPreviewDistrict district={district?.name_with_type} description={location?.description} />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

JobPreviewLocation2.propTypes = {
    location: PropTypes.object.isRequired,
};

export default JobPreviewLocation2;
