import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import 'react-loading-skeleton/dist/skeleton.css';

import { FaLocationDot } from 'react-icons/fa6';

import styles from './JobPreviewLocation.module.scss';
import InputSelectorComponent from '../../../../../components/common/InputSelectorComponent/InputSelectorComponent';
import JobPreviewDistrict from '../JobPreviewDistrict/JobPreviewDistrict';

const cx = classNames.bind(styles);

const JobPreviewLocation = ({ location }) => {
    return (
        <div className={cx('wrapper')}>
            <div className={cx('container')}>
                <div className={cx('location-box')}>
                    <div className={cx('location-box__province')}>
                        <div className={cx('location-box__province-title')}>
                            <FaLocationDot className={cx('title-icon')} />
                            <span className={cx('title-text')}>Khu vực:</span>
                        </div>
                        <div className={cx('location-box__province-content')}>
                            <InputSelectorComponent defaultValue={location?.province?.name_with_type} options={[]} disabled={true} />
                        </div>
                    </div>
                </div>
                {location?.district?.id > 0 && (
                    <div className={cx('address-box')}>
                        <div className={cx('address-box__district')}>
                            <JobPreviewDistrict district={location?.district?.name_with_type} description={location?.description} />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

JobPreviewLocation.propTypes = {
    location: PropTypes.object.isRequired,
};

export default JobPreviewLocation;
