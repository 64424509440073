import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import TippyText from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

import { FaChevronDown, FaEye, FaPlus } from 'react-icons/fa6';
import { HiOutlineChevronDoubleRight, HiOutlineChevronDoubleLeft } from 'react-icons/hi';
import { IoSearchOutline } from 'react-icons/io5';

import styles from './DashboardCVPage.module.scss';
import { SelectionComponent } from '@components/common';
import { SkeletonManagerUsercomponent } from '@components/skeleton';
import { selectUser } from '@redux/features/authUser/authSlide';
import { getListCVApplicationsService } from '../../../services/business/businessCVApplicationsService';
import { cVStatus, groupRole } from '../../../constants';
import { CVRowComponent } from '../../../components';
import { hideCV } from '../../../redux/features/cvDetail/cvDetailSlice';
import { hideModal } from '../../../redux/features/modal/modalSlice';
import { getListCampaignService } from '../../../services/business/campaignService';

const cx = classNames.bind(styles);

const DashboardCVPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const campaign_id = query.get('campaign_id');

    const dispatch = useDispatch();
    const user = useSelector(selectUser);
    const campaignId = campaign_id ? parseInt(campaign_id) : -1;
    const [listFilterCampaign, setListFilterCampaign] = useState({
        isLoading: true,
        campaigns: [],
    });

    const listFilterBusiness = [
        {
            id: 1,
            name: 'Hiển thị tất cả CV',
        },
        {
            id: 2,
            name: 'Chỉ hiển thị CV chưa xem',
        },
    ];
    const listFilterStatus = [
        {
            id: 1,
            name: 'Tất cả trạng thái',
            value: 'all',
        },
        {
            id: 2,
            name: 'Chưa xem',
            value: 'pending',
        },
        {
            id: 3,
            name: 'Đã xem',
            value: 'viewed',
        },
        {
            id: 4,
            name: 'Đã mời phỏng vấn',
            value: 'interview',
        },
        {
            id: 5,
            name: 'Đã từ chối',
            value: 'rejected',
        },
    ];

    const [business, setBusiness] = useState(null);
    const [cv, setCv] = useState({
        cv: [],
        count: 0,
    });

    const [filterBusiness, setFilterBusiness] = useState({
        filter_by: 1,
        page: 1,
        loading: true,
        canLoadMore: true,
        campaign_id: campaignId,
        status: 'all',
        filter_viewed: null,
    });

    const [keyword, setKeyword] = useState('');

    const handlePrevPage = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setFilterBusiness({ ...filterBusiness, page: filterBusiness.page - 1, loading: true });
    };

    const handleNextPage = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setFilterBusiness({ ...filterBusiness, page: filterBusiness.page + 1, loading: true });
    };

    const handleFilterBusiness = (filter) => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setFilterBusiness({ ...filterBusiness, filter_by: filter, page: 1, loading: true });
    };

    const handleFilterCampaign = (campaign_id) => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setFilterBusiness({ ...filterBusiness, campaign_id, page: 1, loading: true });
        if (campaign_id === -1) {
            // query.delete('campaign_id');
            navigate(``);
        } else {
            navigate(`?campaign_id=${campaign_id}`);
        }
    };

    const handleFilterStatus = (filter_status) => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setFilterBusiness({ ...filterBusiness, status: filter_status, page: 1, loading: true });
    };

    useEffect(() => {
        const params = {
            skip: 0,
            limit: 1000,
        };
        getListCampaignService(params)
            .then((res) => {
                if (res.status === 200) {
                    const allCampaign = {
                        id: -1,
                        title: 'Tất cả chiến dịch',
                    };
                    setListFilterCampaign({
                        isLoading: false,
                        campaigns: [allCampaign, ...res.data.data.campaigns],
                    });
                } else {
                    setListFilterCampaign({
                        isLoading: false,
                        campaigns: [],
                    });
                }
            })
            .catch((err) => {
                console.log(err);
                setListFilterCampaign({
                    isLoading: false,
                    campaigns: [],
                });
            });
    }, []);

    useEffect(() => {
        const params = {
            skip: (filterBusiness.page - 1) * 10,
            limit: 10,
            sort_by: 'created_at',
            order_by: 'desc',
            ...(filterBusiness.filter_by === 2 && { is_viewed: false }),
            ...(keyword && { keyword }),
            ...(filterBusiness.status && filterBusiness.status !== 'all' && { status: filterBusiness.status }),
            ...(filterBusiness.campaign_id && filterBusiness.campaign_id !== -1 && { campaign_id: filterBusiness.campaign_id }),
        };

        filterBusiness.loading &&
            getListCVApplicationsService(params)
                .then((res) => {
                    if (res.status === 200) {
                        setCv((prev) => ({
                            ...prev,
                            cv: res.data.data.cv,
                            count: res.data.data.count,
                        }));
                        setTimeout(() => {
                            setFilterBusiness({ ...filterBusiness, loading: false, canLoadMore: res.data.data.length === 10 });
                        }, 500);
                    } else if (res.status === 404) {
                        setCv((prev) => ({
                            ...prev,
                            cv: [],
                            count: 0,
                        }));
                        setTimeout(() => {
                            setFilterBusiness((prev) => ({ ...prev, loading: false, canLoadMore: false }));
                        }, 500);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
    }, [filterBusiness]);

    useEffect(() => {
        dispatch(hideCV());
        dispatch(hideModal());
    }, []);

    return (
        <div className={cx('wrapper')}>
            <div className={cx('auth-modal')}></div>
            <div className={cx('container')}>
                <div className={cx('breadcrumb-box')}>
                    <div className={cx('breadcrumb-box-title')}>
                        <h6 className={cx('breadcrumb')}>Quản lý CV</h6>
                    </div>
                </div>
                <div className={cx('content')}>
                    <div className={cx('content-list')}>
                        <div className={cx('box-search')}>
                            <div className={cx('box-search-campaign')}>
                                <input
                                    type="text"
                                    placeholder={`Tìm người dùng theo email, tên ${filterBusiness.filter_by === 2 ? ', công ty ' : ''}(Nhấn enter để tìm kiếm)`}
                                    className={cx('input-search')}
                                    value={keyword}
                                    onChange={(e) => setKeyword(e.target.value)}
                                    onKeyPress={(e) => e.key === 'Enter' && setFilterBusiness({ ...filterBusiness, page: 1, loading: true })}
                                />
                                <IoSearchOutline
                                    className={cx('icon-search')}
                                    onClick={() => setFilterBusiness({ ...filterBusiness, page: 1, loading: true })}
                                />
                            </div>
                            <div className={cx('box-filter-campaign')}>
                                <SelectionComponent
                                    header={() => (
                                        <div className={cx('header-select')} style={{ width: '230px' }}>
                                            <div className={cx('container-select')}>
                                                <span className={cx('result')}>
                                                    {listFilterBusiness.find((item) => item.id === filterBusiness.filter_by)?.name}
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                    body={() => (
                                        <ul className={cx('ul-select')}>
                                            {listFilterBusiness.map((item) => (
                                                <li
                                                    key={item.id}
                                                    className={cx('item', { active: item.id === filterBusiness.filter_by })}
                                                    onClick={() => handleFilterBusiness(item.id)}
                                                >
                                                    <span className={cx('text')}>{item.name}</span>
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                    icon={() => <FaChevronDown className={cx('icon-care')} />}
                                    itemSelect={listFilterBusiness.find((item) => item.id === filterBusiness.filter_by)?.name}
                                    maxHeight={'300px'}
                                    styleDropdown={{
                                        right: 'auto',
                                        left: '0',
                                        top: '45px',
                                        width: '250px',
                                        borderRadius: '4px',
                                        border: '1px solid #e8e8e8',
                                        borderTop: 'none',
                                    }}
                                    styleButton={{ marginRight: '10px' }}
                                />
                                {!listFilterCampaign.isLoading && (
                                    <SelectionComponent
                                        header={() => (
                                            <div className={cx('header-select')}>
                                                <div className={cx('container-select')}>
                                                    <span
                                                        className={cx('result')}
                                                        style={{ width: '250px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                                                    >
                                                        {listFilterCampaign.campaigns.find((item) => item.id === filterBusiness.campaign_id)?.title ||
                                                            'Chọn chiến dịch'}
                                                    </span>
                                                </div>
                                            </div>
                                        )}
                                        body={() => (
                                            <ul className={cx('ul-select')}>
                                                {listFilterCampaign.campaigns.map((item) => (
                                                    <li
                                                        key={item.id}
                                                        className={cx('item', { active: item.id === filterBusiness.campaign_id })}
                                                        onClick={() => handleFilterCampaign(item.id)}
                                                    >
                                                        <span className={cx('text')}>{item.title}</span>
                                                    </li>
                                                ))}
                                            </ul>
                                        )}
                                        icon={() => <FaChevronDown className={cx('icon-care')} />}
                                        itemSelect={listFilterCampaign.campaigns.find((item) => item.id === filterBusiness.campaign_id)?.title}
                                        maxHeight={'300px'}
                                        styleDropdown={{
                                            right: 'auto',
                                            left: '0',
                                            top: '45px',
                                            width: 'auto',
                                            borderRadius: '4px',
                                            border: '1px solid #e8e8e8',
                                            borderTop: 'none',
                                            overflow: 'auto',
                                        }}
                                        styleButton={{ marginRight: '10px' }}
                                    />
                                )}
                                <SelectionComponent
                                    header={() => (
                                        <div className={cx('header-select')} style={{ width: '180px' }}>
                                            <div className={cx('container-select')}>
                                                <span className={cx('result')}>
                                                    {listFilterStatus.find((item) => item.value === filterBusiness.status)?.name}
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                    body={() => (
                                        <ul className={cx('ul-select')}>
                                            {listFilterStatus.map((item) => (
                                                <li
                                                    key={item.id}
                                                    className={cx('item', { active: item.value === filterBusiness.status })}
                                                    onClick={() => handleFilterStatus(item.value)}
                                                >
                                                    <span className={cx('text')}>{item.name}</span>
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                    icon={() => <FaChevronDown className={cx('icon-care')} />}
                                    itemSelect={listFilterStatus.find((item) => item.value === filterBusiness.status)?.name}
                                    maxHeight={'300px'}
                                    styleDropdown={{
                                        right: 'auto',
                                        left: '0',
                                        top: '45px',
                                        width: '180px',
                                        borderRadius: '4px',
                                        border: '1px solid #e8e8e8',
                                        borderTop: 'none',
                                    }}
                                    styleButton={{ marginRight: '10px' }}
                                />
                            </div>
                        </div>
                        <div className={cx('result-count')}>
                            <span className={cx('count')}>Tìm thấy {cv.count} ứng viên</span>
                        </div>
                        <table className={cx('table')}>
                            <thead className={cx('table-head')}>
                                <tr>
                                    <th className={cx('table-head-item')}></th>
                                    <th className={cx('table-head-item')}>Ứng viên</th>
                                    <th className={cx('table-head-item')}>Thông tin</th>
                                    <th className={cx('table-head-item')}>Chiến dịch</th>
                                    <th className={cx('table-head-item')}>Trạng thái</th>
                                    {/* {filterBusiness.filter_by === 2 && <th className={cx('table-head-item')}>Công ty</th>} */}
                                    {groupRole.ADMIN.includes(user?.role) && <th className={cx('table-head-item')}>Công ty</th>}
                                </tr>
                            </thead>
                            <tbody className={cx('table-body')}>
                                {cv.cv && !filterBusiness.loading
                                    ? cv.cv.map((item) => <CVRowComponent key={item.id} cv={item} />)
                                    : Array.from({ length: 6 }).map((_, index) => (
                                          <tr className={cx('table-body-row')} key={index}>
                                              <td colSpan="6" className={cx('table-body-empty')}>
                                                  <SkeletonManagerUsercomponent />
                                              </td>
                                          </tr>
                                      ))}
                            </tbody>
                        </table>
                    </div>

                    {business && business.business?.length > 0 && (
                        <div className={cx('footer')}>
                            <div className={cx('content-footer')}>
                                <span
                                    className={cx('btn', filterBusiness.page === 1 ? 'deactive' : '')}
                                    onClick={handlePrevPage}
                                    disabled={filterBusiness.page === 1}
                                >
                                    <HiOutlineChevronDoubleLeft className={cx('icon')} />
                                </span>
                                <p className={cx('text-page')}>
                                    Trang <span className={cx('number')}>{filterBusiness.page}</span>
                                </p>
                                <span
                                    className={cx('btn', !filterBusiness.canLoadMore ? 'deactive' : '')}
                                    onClick={handleNextPage}
                                    disabled={!filterBusiness.canLoadMore}
                                >
                                    <HiOutlineChevronDoubleRight className={cx('icon')} />
                                </span>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default DashboardCVPage;
