import classNames from 'classnames/bind';
import PropTypes from 'prop-types';

import { FaRegFileLines } from 'react-icons/fa6';

import styles from './IconAttachmentMessage.module.scss';

const cx = classNames.bind(styles);

const IconAttachmentMessage = ({ attachment, attachments, removeAttachment, isUploading }) => {
    const handleRemove = (file) => {
        const index = attachments.findIndex((item) => item.file === file);
        if (index !== -1) {
            removeAttachment(index);
        }
    };

    return (
        <div className={cx('wrapper')}>
            {attachment.file.type.includes('image') ? (
                <img alt="attachment" className={cx('image')} src={URL.createObjectURL(attachment.file)} />
            ) : (
                <span className={cx('file')}>
                    <FaRegFileLines className={cx('icon')} />
                    <span className={cx('name')}>{attachment.file.name}</span>
                </span>
            )}

            <button className={cx('remove')} onClick={() => handleRemove(attachment.file)} disabled={isUploading}>
                X
            </button>
        </div>
    );
};

IconAttachmentMessage.propTypes = {
    attachment: PropTypes.object,
    attachments: PropTypes.array,
    removeAttachment: PropTypes.func,
    isUploading: PropTypes.bool,
};

export default IconAttachmentMessage;
