import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import { Avatar, Conversation, Button } from '@chatscope/chat-ui-kit-react';
import TippyText from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

import styles from './ChatSidebarBusinessItemComponent.module.scss';
import { images } from '@assets';

const cx = classNames.bind(styles);

const ChatSidebarBusinessItemComponent = ({ contact, onClick }) => {
    const { job } = contact;

    return (
        <Conversation
            className={cx('wrapper')}
            name={
                <span>
                    <TippyText content={contact.email}>
                        <div className={cx('job-name')}>
                            {contact.full_name} | {contact.email}
                        </div>
                    </TippyText>
                    <Button className={cx('btn-message')} onClick={onClick}>
                        <span>Nhắn tin</span>
                    </Button>
                </span>
            }
            lastSenderName={
                <TippyText content={job.title}>
                    <div className={cx('company-name')}>{job.title}</div>
                </TippyText>
            }
        >
            <Avatar src={contact.avatar || images.avatar_default} name={contact.full_name} onError={(e) => (e.target.src = images.avatar_default)} />
        </Conversation>
    );
};

ChatSidebarBusinessItemComponent.propTypes = {
    contact: PropTypes.object.isRequired,
    onClick: PropTypes.func,
};

export default ChatSidebarBusinessItemComponent;
