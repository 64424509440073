import React, { useState } from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';

import { Avatar, Message } from '@chatscope/chat-ui-kit-react';

import Lightbox from 'yet-another-react-lightbox';
import Download from 'yet-another-react-lightbox/plugins/download';
import Counter from 'yet-another-react-lightbox/plugins/counter';
import Thumbnails from 'yet-another-react-lightbox/plugins/thumbnails';
import 'yet-another-react-lightbox/styles.css';
import 'yet-another-react-lightbox/plugins/thumbnails.css';
import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';

import styles from './ImageMessage.module.scss';
import { icons } from '../../assets';

const cx = classNames.bind(styles);

const ImageMessage = ({ images }) => {
    const [lightboxIsOpen, setLightboxIsOpen] = useState(false);

    return (
        <Message
            key={111111111111}
            model={{
                message: 'Test image',
                sentTime: '12:00',
                sender: 'Test',
                direction: 'incoming',
                position: 'single',
            }}
            // onClick={() => setLightboxIsOpen(true)}
        >
            <Avatar
                className={cx('avatar')}
                src={icons.icon_default_logo_company}
                name={'Test'}
                style={{
                    visibility: 'visible',
                }}
            />
            <Message.CustomContent>
                <img
                    src="https://gratisography.com/wp-content/uploads/2024/10/gratisography-cool-cat-800x525.jpg"
                    alt="image"
                    style={{ maxWidth: '300px', maxHeight: '300px', cursor: 'pointer' }}
                    onClick={() => setLightboxIsOpen(true)}
                />
                <Lightbox
                    plugins={[Thumbnails, Counter, Download]}
                    thumbnails={{
                        position: 'bottom',
                        width: 100,
                        height: 60,
                        border: '1px solid #fff',
                        borderRadius: 10,
                        padding: 2,
                        gap: 10,
                    }}
                    carousel={{ preload: 5 }}
                    open={lightboxIsOpen}
                    close={() => setLightboxIsOpen(false)}
                    slides={images.map((image) => ({
                        src: image.url,
                    }))}
                />
            </Message.CustomContent>
        </Message>
    );
};

ImageMessage.propTypes = {
    images: PropTypes.array,
};

export default ImageMessage;
